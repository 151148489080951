import { createReducer } from "@reduxjs/toolkit";
import { ActionType } from "../actions";

const initialState = {};

const currentAddressReducer = createReducer(initialState, (builder) => {
    builder.addCase(ActionType.SET_CURRENT_ADDRESS, (state, action) => {
        const { currentAddress } = action;
        for (const key in currentAddress) state[key] = currentAddress[key];
    });
});

export default currentAddressReducer;
