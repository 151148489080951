import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import "./ForemanRow.css";
import Foreman from "./Foreman/Foreman";
import { EProjectStatus } from "../../../../../globals/globals";
import { useCurrentProject } from "../../../../../Redux/useGlobalState";
import DateStamp from "./DateStamp/DateStamp";

const propTypes = {
    projectStatusAlias: PropTypes.string,
    formatDate: PropTypes.func,
    estimatedStartDate: PropTypes.instanceOf(Date),
    estimatedCompletionDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    projectCompletionDate: PropTypes.instanceOf(Date),
    foreman: PropTypes.instanceOf(Object),
    contentSeparator: PropTypes.string,
};

const defaultProps = {
    projectStatusAlias: null,
    formatDate: null,
    estimatedStartDate: null,
    estimatedCompletionDate: null,
    projectCompletionDate: null,
    foreman: null,
    contentSeparator: null,
};

export default function ForemanRow({
    projectStatusAlias,
    formatDate,
    estimatedStartDate,
    estimatedCompletionDate,
    projectCompletionDate,
    foreman,
    contentSeparator,
}) {
    const currentProject = useCurrentProject();
    const currentProjectAlias = currentProject.currentProjectStatus.alias;
    const isProjectComplete =
        currentProjectAlias === EProjectStatus.ProjectComplete;

    return (
        <>
            <Row className="forman-row">
                <Col
                    className="gotham-book-normal-black-16px forman-row-col-date"
                    // style={{
                    //     padding: 0,
                    //     maxWidth: "115px",
                    // }}
                >
                    <DateStamp
                        label={
                            ([EProjectStatus.ProjectPreparation].includes(
                                projectStatusAlias
                            ) &&
                                "Project Start Date") ||
                            ([EProjectStatus.ProjectInProgress].includes(
                                projectStatusAlias
                            ) &&
                                (!isProjectComplete
                                    ? "Estimated Completion Date:"
                                    : "Completed Date")) ||
                            null
                        }
                        date={
                            ([EProjectStatus.ProjectPreparation].includes(
                                projectStatusAlias
                            ) &&
                                formatDate(estimatedStartDate)) ||
                            ([EProjectStatus.ProjectInProgress].includes(
                                projectStatusAlias
                            ) &&
                                formatDate(
                                    !isProjectComplete
                                        ? estimatedCompletionDate
                                        : projectCompletionDate,
                                    "TO BE SCHEDULED"
                                )) ||
                            null
                        }
                    />
                </Col>
                <Col
                    style={{
                        padding: 0,
                        maxWidth: "80px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <Foreman label={false} />
                </Col>
                <Col
                    style={{
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "6px",
                    }}
                >
                    <div className="gotham-book-normal-abbey-14px">
                        Your Foreman:
                    </div>
                    <div className="gotham-bold-black-16px">
                        {foreman?.name}
                    </div>
                </Col>
            </Row>
            <img
                className="project-state-content-separator"
                src={contentSeparator}
                alt="Project State Content Separator"
                style={{ marginTop: "10px" }}
            />
        </>
    );
}

ForemanRow.propTypes = propTypes;
ForemanRow.defaultProps = defaultProps;
