import React from "react";
import PropTypes from "prop-types";
import "./ProjectStateCompleted.css";
import { responsiveClassName } from "../../../../../../utils/responsive";

const propTypes = {
    isComplete: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    isGrayed: PropTypes.bool,
    className: PropTypes.string,
    isWorkflow: PropTypes.bool,
    isHomeProtection: PropTypes.bool,
    isDailyWorkTask: PropTypes.bool,
};

const defaultProps = {
    isComplete: false,
    isGrayed: true,
    className: null,
    isWorkflow: false,
    isHomeProtection: false,
    isDailyWorkTask: false,
};

/**
 * @typedef {Object} Props
 * @property {TProject} project
 * @property {String} status
 * @property {Boolean} isHomeProtection
 * @property {Boolean} isDailyWorkTask
 */

const projectStateIcon = (
    isGrayed,
    isComplete,
    isHomeProtectionIncomplete,
    isWorkflow,
    isDailyWorkTask
) => {
    return (
        (!isGrayed &&
            (isHomeProtectionIncomplete ||
                (!isComplete && isDailyWorkTask)) && (
                <div
                    className={`gotham-bold-black-22px ${
                        isDailyWorkTask && "project-state-check-dailyworktask"
                    }`}
                >
                    !
                </div>
            )) || (
            <div
                className={`project-state-check ${
                    (isGrayed && "fontawesome6pro-solid-normal-grayed-16px") ||
                    (isComplete
                        ? "fontawesome6pro-solid-normal-black-20px"
                        : "fontawesome6pro-solid-normal-white-16px")
                } ${isWorkflow && "project-state-check-workflow"}`}
            >
                
            </div>
        )
    );
};

/**
 * @param {Props} props
 */
export default function ProjectStateCompleted(props) {
    const {
        isComplete,
        isGrayed,
        className,
        isWorkflow,
        isHomeProtection,
        isDailyWorkTask,
    } = props;

    const isHomeProtectionIncomplete = isHomeProtection && !isComplete;

    return (
        <div
            className={`${responsiveClassName(
                "project-state-completed"
            )} ${className}`}
            style={{
                backgroundColor:
                    (!isGrayed &&
                        isHomeProtectionIncomplete &&
                        "var(--white)") ||
                    (!isGrayed && isDailyWorkTask && "var(--orange)") ||
                    (isGrayed && "var(--grayed)") ||
                    (isComplete ? "var(--feijoa)" : "var(--cloud-burst)"),
                border:
                    !isGrayed && isHomeProtectionIncomplete
                        ? "solid 3px var(--orange)"
                        : "none",
            }}
        >
            {projectStateIcon(
                isGrayed,
                isComplete,
                isHomeProtectionIncomplete,
                isWorkflow,
                isDailyWorkTask
            )}
        </div>
    );
}

ProjectStateCompleted.propTypes = propTypes;

ProjectStateCompleted.defaultProps = defaultProps;
