import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { isDesktop, isMobile } from "react-device-detect";
import "./AccountDetails.css";
import { formatPhoneNumber } from "../../../../utils/format";
import { EOrientation } from "./enums";
import { useUser, useUserSession } from "../../../../Redux/useGlobalState";
import { responsiveClassName } from "../../../../utils/responsive";
import Control from "../../../../Controls/Control";
import pixel from "../../../../static/img/pixel.png";

export default function AccountDetails() {
    const user = useUser();
    const { isAdminUser } = useUserSession();

    const genericUser = user.userId === 0;

    const userDetails = [
        {
            label: "Name",
            value: genericUser
                ? "your name"
                : `${user.firstName} ${user.lastName}`,
        },
        {
            label: "Phone Number",
            value: formatPhoneNumber(
                user.phoneNumber,
                isAdminUser && !user.userId ? "xxx-xxx-xxxx" : ""
            ),
        },
        {
            label: "Email",
            value: genericUser ? "your email" : user.emailAddress,
        },
    ];

    if (isMobile) {
        userDetails.splice(1, 0, userDetails[2]);
        userDetails.splice(3, 1);
    }

    const colClassName = (baseClassName, suffix, condition) => {
        let colClassName = `col ${baseClassName}`;
        if (condition)
            colClassName = `${colClassName} ${baseClassName}-${suffix}`;
        return colClassName;
    };

    const displayResponsive = () => {
        const divLeft = document.getElementById("colAccountDetailsUserCol1_0");
        const divRight = document.getElementById("colAccountDetailsUserCol1_1");
        if (!divLeft || !divRight) return;

        const stacked = divLeft.offsetTop !== divRight.offsetTop;

        divLeft.className = colClassName(
            "account-details-user-col",
            EOrientation.Left.toLowerCase(),
            !stacked
        );

        divRight.className = colClassName(
            "account-details-user-col",
            EOrientation.Right.toLowerCase(),
            !stacked
        );
    };

    useEffect(() => displayResponsive(), []);

    window.addEventListener("resize", () => displayResponsive(), true);

    const emailBreak = <img alt="" src={pixel} width="0px" />;

    return (
        <div className="account-details">
            <div>
                <div className="gotham-bold-black-26px account-details-header">
                    Contact Information
                </div>
                <div
                    className={`cp-text gotham-book-normal-cloud-burst-18px ${responsiveClassName(
                        "account-details-contact-phone"
                    )}`}
                >
                    If you need to make changes to the below information, please
                    email us at <Control.Link.CustomerSupport />.
                </div>
            </div>
            <Row className={responsiveClassName("account-details-user-row")}>
                {[0, 1].map((colIndex) => {
                    if (isMobile && colIndex === 1) return;
                    const columnOrientation =
                        colIndex === 0 ? EOrientation.Left : EOrientation.Right;
                    return (
                        <Col
                            id={`colAccountDetailsUserCol1_${colIndex}`}
                            className={`account-details-user-col account-details-user-col-${columnOrientation.toLowerCase()}`}
                            key={`userDetailCol_${colIndex.toString()}`}
                        >
                            {userDetails.map((userDetail, index) => {
                                if (isDesktop && index % 2 !== colIndex) return;

                                const detailValue = userDetail.value;

                                return (
                                    <div
                                        className={responsiveClassName(
                                            "account-details-user-property"
                                        )}
                                        key={`userDetail_${index.toString()}`}
                                    >
                                        <div className="gotham-bold-cloud-burst-20px account-details-user-label">{`${userDetail.label}:`}</div>
                                        <div
                                            title={detailValue}
                                            className="gotham-book-normal-cloud-burst-20px account-details-user-value"
                                            style={{ wordWrap: "break-word" }}
                                        >
                                            {detailValue
                                                .split("@")
                                                .map((value, index, items) => (
                                                    <>
                                                        {value}
                                                        {index <
                                                        items.length - 1 ? (
                                                            <>@{emailBreak}</>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                ))}
                                        </div>
                                    </div>
                                );
                            })}
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
}
