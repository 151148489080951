import React from "react";
import { deviceType } from "react-device-detect";
import "./ProjectDocuments.css";
import { Link } from "react-router-dom";
import iconPlay from "../../../../static/img/path-60-11@1x.png";
import { useCurrentProject } from "../../../../Redux/useGlobalState";

export default function ProjectDocuments() {
    let { documents } = useCurrentProject();

    documents = documents ? [...documents] : [];
    documents.sort((document1, document2) => {
        const documentDescription1 = document1.description;
        const documentDescription2 = document2.description;
        if (documentDescription1 < documentDescription2) return -1;
        if (documentDescription1 > documentDescription2) return +1;
        return 0;
    });

    return (
        <div className="project-documents">
            <div className="project-documents-title gotham-medium-cloud-burst-18px">
                <div className="icon-pdf">file-pdf</div>
                <div className="project-documents-title-text">
                    Project Documents:
                </div>
            </div>
            <div
                className={`project-documents-items ${
                    deviceType === "mobile" && "project-documents-items-mobile"
                }`}
            >
                {documents.map(
                    (document, index) =>
                        index < 40 && (
                            <div
                                className="project-documents-item"
                                key={document.id}
                            >
                                <img
                                    className="icon-play"
                                    src={iconPlay}
                                    alt="icon-play"
                                />
                                <div className="crawlspace-mold-reme gotham-book-normal-black-16px">
                                    <Link
                                        target="_blank"
                                        className="project-documents-link"
                                        to={document.url}
                                    >
                                        {document.documentTypeName}
                                    </Link>{" "}
                                </div>
                            </div>
                        )
                )}
            </div>
        </div>
    );
}
