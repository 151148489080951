import { createReducer } from "@reduxjs/toolkit";
import { ActionType } from "../actions";

const initialState = {};

const personReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(ActionType.SET_PERSON, (state, action) => {
            const { user } = action;
            for (const key in user) state[key] = user[key];
        })
        .addCase(ActionType.ACCEPT_QUOTE, (state, action) => {
            /**
             * @type {Array}
             */
            const properties = state.Person.Properties;
            const propertyIndex = properties.findIndex(
                (property) => property.PropertyId === action.PropertyId
            );
            const projects = properties[propertyIndex].Projects;
            const projectIndex = projects?.findIndex(
                (project) => project.ProjectId === action.ProjectId
            );
            state.Person.Properties[propertyIndex].Projects[
                projectIndex
            ].Quote.Accepted = true;
        })
        .addCase(ActionType.SET_FEATURE_OVERVIEW_COMPLETE, (state, action) => {
            state.isOverviewComplete = action.complete;
        });
});

export default personReducer;
