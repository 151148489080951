import React, { useRef } from "react";
import { Accordion } from "react-bootstrap";
import { useOffers } from "../../../../../Redux/useGlobalState";
import Offer from "./Offer";
import "./Body.css";

export default function Body() {
    const offers = useOffers();

    const divBody = useRef();

    return (
        <div ref={divBody} className="special-offers-body">
            <Accordion>
                {offers.map((offer, offerIndex) => (
                    <Offer
                        key={`offer_${offerIndex.toString()}`}
                        eventKey={`offer_${offerIndex.toString()}`}
                        offer={offer}
                    />
                ))}
            </Accordion>
        </div>
    );
}
