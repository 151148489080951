import React from "react";
import "./UserOptions.css";
import { useDispatch } from "react-redux";
import { useUserSession } from "../../../../Redux/useGlobalState";
import { ActionType } from "../../../../Redux/actions";
import { useLogout } from "../../../../utils/hooks";
import DivInteractive from "../../Content/DivInteractive";

export default function UserOptions() {
    const { logout } = useLogout();

    const menuSelection = useUserSession()?.menuSelection;

    const dispatch = useDispatch();
    const selectMenuItem = (alias) => {
        dispatch({ type: ActionType.SET_MENU_SELECTION, menuSelection: alias });
    };

    return (
        /* test of deployment */
        <div className="user-options-1">
            <div className="user-options-2">
                <div className="text-container fontawesome6prosolid-solid-normal-abbey-20px">
                    <DivInteractive
                        onClick={() => selectMenuItem("AccountDetails")}
                        onKeyUp={() => false}
                        className={`text-4 ${
                            menuSelection === "AccountDetails" &&
                            "user-options-text-selected"
                        }`}
                    >
                        {" "}
                        <span
                            id="spanProfile"
                            className="text-5 gotham-book-normal-abbey-20px"
                        >
                            Account Details
                        </span>
                    </DivInteractive>
                    <DivInteractive
                        onClick={() => {
                            logout(true);
                        }}
                        onKeyUp={() => false}
                        className="text-4"
                    >
                        {" "}
                        <span
                            id="spanLogOut"
                            className="text-5 gotham-book-normal-abbey-20px"
                        >
                            Log Out
                        </span>
                    </DivInteractive>
                </div>
            </div>
        </div>
    );
}
