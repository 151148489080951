import React from "react";
import PropTypes from "prop-types";
import "./Circle.css";

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: "",
};

export default function Circle({ className }) {
    return (
        <div
            className={`fontawesome6pro-solid-normal-black-38px circle ${className}`}
        >
            !
        </div>
    );
}

Circle.propTypes = propTypes;
Circle.defaultProps = defaultProps;
