import React, { useEffect, useRef } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import "./Project.css";
import ProjectStateContext from "./ProjectState/ProjectStateContext";
import ProjectState from "./ProjectState/ProjectState";
import {
    useCurrentAddress,
    useCurrentProject,
    useUserSession,
    useUser,
} from "../../../../Redux/useGlobalState";
import { EProjectStatus } from "../../../../globals/globals";
import ProgressBar from "../../../../ProgressBar/ProgressBar";
import { responsiveClassName } from "../../../../utils/responsive";
import { EProgressMode } from "../../../../ProgressBar/enums";
import UserLoadErrorModal from "./UserLoadErrorModal";
import ViewOtherProjects from "../../../../Controls/ViewOtherProjects";

export default function Project() {
    const user = useUser();
    const { selectedProjectStatus } = useUserSession();

    const currentProject = useCurrentProject();
    const currentProjectId = currentProject.projectId;
    const currentAddress = useCurrentAddress();
    const { isAdminUser, customerNotFound } = useUserSession();

    const projectStatusAlias = currentProject.currentProjectStatus?.alias;

    let projectProgress;
    switch (projectStatusAlias) {
        case EProjectStatus.InspectionBooked:
            projectProgress = 1;
            break;
        case EProjectStatus.InspectionComplete:
            projectProgress = 2;
            break;
        case EProjectStatus.ContractSigned:
            projectProgress = 3;
            break;
        case EProjectStatus.ProjectPreparation:
            projectProgress = 4;
            break;
        case EProjectStatus.ProjectInProgress:
            projectProgress = 5;
            break;
        case EProjectStatus.ProjectComplete:
            projectProgress = 6;
            break;
        default:
            projectProgress = 0;
    }
    if (currentProject.hasServicePlan) projectProgress += 1;
    projectProgress /= 7;

    const divProject = useRef();

    const displayResponsive = () => {
        if (!isDesktop) return;
        const divProj = divProject.current;
        if (!divProj) return;
        const isScrolling = divProj.clientHeight < divProj.scrollHeight;
        divProj.style.paddingRight = `calc(65px${
            isScrolling ? " - 15px" : ""
        })`;
    };

    window.addEventListener("resize", () => displayResponsive(), true);
    useEffect(() => displayResponsive(), []);

    const projectStateContextValue = (status) => {
        return {
            status,
        };
    };

    return (
        <>
            <div className="project-property">
                <div className="project-property-header">
                    <div
                        className={`gotham-bold-cloud-burst-33px  ${
                            isMobile && "gotham-bold-cloud-burst-26px"
                        } project-property-header-left`}
                    >
                        Your Project{" "}
                        {!!currentAddress.addressId && (
                            <>
                                at{" "}
                                {`${currentAddress.address1} ${currentAddress.address2}`}
                            </>
                        )}
                    </div>
                    <div className="project-property-header-right">
                        <ViewOtherProjects />
                    </div>
                </div>
                {customerNotFound && (
                    <div className="gotham-book-normal-black-16px cp-error">
                        {customerNotFound}
                    </div>
                )}
                <hr className="project-property" />
            </div>
            <div className={responsiveClassName("project-progress")}>
                <ProgressBar
                    mode={EProgressMode.Project}
                    progress={projectProgress}
                />
            </div>
            <div
                id="divProject"
                ref={divProject}
                className={`project-1 ${isMobile && "project-1-mobile"}`}
            >
                {(!isAdminUser &&
                    !currentProjectId &&
                    currentProjectId !== 0 &&
                    "This user has no projects.") ||
                    [
                        { status: EProjectStatus.InspectionBooked },
                        { status: EProjectStatus.InspectionComplete },
                        { status: EProjectStatus.ContractSigned },
                        { status: EProjectStatus.ProjectPreparation },
                        { status: EProjectStatus.ProjectInProgress },
                        { status: EProjectStatus.ProjectComplete },
                        {
                            status: EProjectStatus.HomeProtection,
                            isHomeProtection: true,
                        },
                    ].map((value, index) => {
                        const { status } = value;
                        return (
                            <ProjectStateContext.Provider
                                value={projectStateContextValue(status)}
                                key={`ProjectState_${index.toString()}`}
                            >
                                <ProjectState
                                    key={`ProjectState_${index.toString()}`}
                                    project={currentProject}
                                    status={status}
                                    // expanded={status === expandedStatus}
                                    expanded={
                                        value.status === selectedProjectStatus
                                    }
                                    isHomeProtection={!!value.isHomeProtection}
                                    checkCompletedProps={{
                                        className: "check-completed-2",
                                    }}
                                    stateIndex={index}
                                />
                            </ProjectStateContext.Provider>
                        );
                    })}
            </div>
            <UserLoadErrorModal popupOpen={!user.isActive} />
        </>
    );
}
