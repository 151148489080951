import React from "react";
import PropTypes from "prop-types";
import "./ProjectState.css";
import "./ProjectStateNew.css";
import ProjectStateHeader from "./ProjectStateHeader";
import ProjectStateContent from "./ProjectStateContent/ProjectStateContent";
import { EProjectStatus } from "../../../../../globals/globals";
import {
    TProject,
    useCurrentProject,
} from "../../../../../Redux/useGlobalState";
import ContractSignedContent from "./ProjectStateContent/ContractSignedContent";
import ProjectPreparationContent from "./ProjectStateContent/ProjectPreparationContent";
import ProjectInProgressContent from "./ProjectStateContent/ProjectInProgressContent";
import ProjectStateMessage from "./ProjectStateMessage/ProjectStateMessage";
import ForemanRow from "./ForemanRow";
import { formatDate } from "../../../../../utils/format";
import contentSeparator from "../../../../../static/img/line-8-1@1x.png";
import DateStamp from "./DateStamp/DateStamp";

const propTypes = {
    project: PropTypes.instanceOf(Object),
    status: PropTypes.string,
    isHomeProtection: PropTypes.bool,
    expanded: PropTypes.bool,
    stateIndex: PropTypes.number,
    projectStatusHistory: PropTypes.instanceOf(Object),
};

const defaultProps = {
    project: null,
    status: null,
    isHomeProtection: false,
    expanded: false,
    stateIndex: 0,
    projectStatusHistory: null,
};

/**
 * @typedef {Object} Props
 * @property {TProject} project
 * @property {String} status
 * @property {Boolean} isHomeProtection
 */

/**
 * @param {Props} props
 */
export default function ProjectState(props) {
    const { project, status, isHomeProtection, expanded, stateIndex } = props;

    const currentProject = useCurrentProject();

    const {
        contractSignedDate,
        estimatedStartDate,
        estimatedCompletionDate,
        completionDate,
        workflow,
    } = TProject.getProjectDetails(project);

    let documentUrl = null;
    project.documents?.forEach((document) => {
        if (document.documentTypeAlias === "SignedContract")
            documentUrl = document.url;
    });

    const divContentLeft = document.getElementById("divContentLeft");
    const divContentRight = document.getElementById("divContentRight");

    const isStacked = () => {
        return divContentLeft?.offsetTop !== divContentRight?.offsetTop;
    };

    const hasContent = ![
        EProjectStatus.InspectionBooked,
        EProjectStatus.InspectionComplete,
    ].includes(status);

    const calcIsGrayed = () => {
        return (
            !currentProject.projectId ||
            (!isHomeProtection &&
                EProjectStatus.comesBefore(
                    project.currentProjectStatus?.alias,
                    status
                ))
        );
    };

    const isGrayed = calcIsGrayed();

    const getProjectStatus = (alias) => {
        let result = null;

        project.projectStatusHistory?.forEach((statusHistoryItem) => {
            const { projectStatus } = statusHistoryItem;
            if (projectStatus.alias === alias) {
                result = projectStatus;
            }
        });
        return result;
    };

    const projectStatus = isHomeProtection
        ? {
              alias: EProjectStatus.HomeProtection,
          }
        : getProjectStatus(status);

    const contractUploaded = !!documentUrl;
    const projectStateMessage = (
        <div>
            <ProjectStateMessage
                projectStatus={projectStatus}
                estimatedStartDate={estimatedStartDate}
                contractUploaded={contractUploaded}
                workflow={workflow}
            />
        </div>
    );

    const projectStatusAlias = projectStatus?.alias;

    const { foreman } = currentProject;
    const foremanRow = (
        <ForemanRow
            projectStatusAlias={projectStatusAlias}
            formatDate={formatDate}
            estimatedStartDate={estimatedStartDate}
            estimatedCompletionDate={estimatedCompletionDate}
            projectCompletionDate={completionDate}
            foreman={foreman}
            contentSeparator={contentSeparator}
        />
    );

    const isProjectPreparation =
        projectStatusAlias === EProjectStatus.ProjectPreparation;
    const isProjectInProgress =
        projectStatusAlias === EProjectStatus.ProjectInProgress;

    const projectStateStamp = (
        <div
            style={{
                widows: "100%",
            }}
        >
            <DateStamp
                label={
                    (isProjectPreparation && "Project Start Date") ||
                    (isProjectInProgress &&
                        (!completionDate
                            ? "Estimated Completion Date"
                            : "Completed Date")) ||
                    null
                }
                date={
                    (isProjectPreparation && estimatedStartDate) ||
                    (isProjectInProgress &&
                        formatDate(
                            estimatedCompletionDate,
                            "TO BE SCHEDULED"
                        )) ||
                    null
                }
            />
        </div>
    );

    const displayContent =
        expanded ||
        (project.currentProjectStatus?.alias ===
            EProjectStatus.ProjectComplete &&
            isHomeProtection);

    return (
        <div
            className={`project-state-new ${
                isGrayed && "project-state-new-grayed"
            }`}
        >
            <ProjectStateHeader
                isOpen={expanded}
                project={project}
                status={status}
                isHomeProtection={isHomeProtection}
                hasContent={hasContent}
                isGrayed={isGrayed}
                stateIndex={stateIndex}
            />
            {hasContent && !isGrayed && project.currentProjectStatus && (
                <div style={{ display: displayContent ? "block" : "none" }}>
                    {status === EProjectStatus.ContractSigned && (
                        <ContractSignedContent
                            projectStatus={
                                isHomeProtection
                                    ? {
                                          alias: EProjectStatus.HomeProtection,
                                      }
                                    : getProjectStatus(status)
                            }
                            estimatedStartDate={estimatedStartDate}
                            contractSignedDate={contractSignedDate}
                            contractUploaded={!!documentUrl}
                            workflow={workflow}
                            isStacked={isStacked}
                            documentUrl={documentUrl}
                            status={status}
                        />
                    )}
                    {status === EProjectStatus.ProjectPreparation && (
                        <ProjectPreparationContent
                            projectStatus={projectStatus}
                            estimatedStartDate={estimatedStartDate}
                            contractSignedDate={contractSignedDate}
                            contractUploaded={contractUploaded}
                            workflow={workflow}
                            isStacked={isStacked}
                            documentUrl={documentUrl}
                            status={status}
                            projectStateMessage={projectStateMessage}
                            foremanRow={foremanRow}
                            projectStateStamp={projectStateStamp}
                        />
                    )}
                    {status === EProjectStatus.ProjectInProgress && (
                        <ProjectInProgressContent
                            projectStatus={projectStatus}
                            estimatedStartDate={estimatedStartDate}
                            contractSignedDate={contractSignedDate}
                            contractUploaded={contractUploaded}
                            workflow={workflow}
                            isStacked={isStacked}
                            documentUrl={documentUrl}
                            status={status}
                            projectStateMessage={projectStateMessage}
                            foremanRow={foremanRow}
                            projectStateStamp={projectStateStamp}
                        />
                    )}
                    {[
                        EProjectStatus.ProjectComplete,
                        EProjectStatus.HomeProtection,
                    ].includes(status) && (
                        <ProjectStateContent
                            projectStatus={projectStatus}
                            estimatedStartDate={estimatedStartDate}
                            contractSignedDate={contractSignedDate}
                            contractUploaded={contractUploaded}
                            workflow={workflow}
                            isStacked={isStacked}
                            documentUrl={documentUrl}
                            status={status}
                            projectStateMessage={projectStateMessage}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

ProjectState.propTypes = propTypes;
ProjectState.defaultProps = defaultProps;
