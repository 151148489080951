import React from "react";
import { isDesktop, isMobile } from "react-device-detect";
import {
    useCurrentAddress,
    useUser,
    useUserSession,
} from "../../../../../Redux/useGlobalState";
import "./Address.css";
import { EMenuSelection } from "../../../../../globals/globals";
import ViewOtherProjects, {
    hasManyProjects,
} from "../../../../../Controls/ViewOtherProjects";

const propertyLabel = (isMultiProperty, menuSelection) => `${
    (isMultiProperty &&
        `You Are Viewing ${
            (menuSelection === EMenuSelection.Offers && "Special Offers") ||
            (menuSelection === EMenuSelection.AccountDetails &&
                "Account Details")
        } for This `) ||
    (!isMobile && "Your ") ||
    " "
}
Property:`;

const shortAddress = (address) => {
    let shortAddress = address.address1;
    const { address2 } = address;
    if (address2) shortAddress += ` ${address2}`;
    shortAddress += `, ${address.state}`;
    return shortAddress;
};

export default function Address() {
    /* * * use global state * * */
    const address = useCurrentAddress();
    const user = useUser();
    const userSession = useUserSession();

    const isMultiProperty =
        [EMenuSelection.Offers, EMenuSelection.AccountDetails].includes(
            userSession.menuSelection
        ) && hasManyProjects(user);

    const { menuSelection } = userSession;

    const label = propertyLabel(isMultiProperty, menuSelection);

    return (
        (isMobile && isMultiProperty && (
            <>
                <div className="property-address property-address-multi-project">
                    <div className="gotham-book-normal-black-18px property-address-header">
                        {label}
                    </div>
                    <div className="gotham-book-normal-black-18px">
                        {shortAddress(address)}
                        <div style={{ display: "inline", marginLeft: "15px" }}>
                            <ViewOtherProjects linkText="Change" />
                        </div>
                    </div>
                </div>
                <hr className="property-address property-address-multi-project" />
            </>
        )) || (
            <>
                <div className="property-address property-address-single-project">
                    <div className="gotham-book-normal-black-18px property-address-header">
                        {label}
                    </div>
                    <div
                        className={`${
                            isDesktop
                                ? "gotham-book-normal-black-22px"
                                : "gotham-book-normal-black-18px"
                        } property-address-content`}
                    >
                        {user.userId === 0
                            ? "your property address"
                            : shortAddress(address)}
                        {!isMobile && user.userId !== 0 && (
                            <>
                                <br />
                                {user.userId === 0
                                    ? "your property address"
                                    : `${address.city}, ${address.state} ${address.zip}`}
                            </>
                        )}
                        {!isMobile && isMultiProperty && (
                            <div style={{ marginTop: "10px" }}>
                                <ViewOtherProjects linkText="Change" />
                            </div>
                        )}
                    </div>
                    {isDesktop && <hr className="property-address" />}
                </div>
                {isMobile && <hr className="property-address" />}
            </>
        )
    );
}
