import { createReducer } from "@reduxjs/toolkit";
import { ActionType } from "../actions";

const initialState = {
    isSet: false,
    description: null,
    message: null,
};

const errorReducer = createReducer(initialState, (builder) => {
    builder.addCase(ActionType.SET_ERROR, (state, action) => {
        state.isSet = true;
        for (const key in action) {
            if (key !== "type") state[key] = action[key];
        }
    });
});

export default errorReducer;
