import React, { useState } from "react";
import "./DailyWork.css";
import {
    TDailyWorkGroup,
    TProject,
    useCurrentProject,
} from "../../../../../../../Redux/useGlobalState";
import DailyWorkPopup from "./DailyWorkPopup";
import { EDailyWorkGroup } from "./enums";
import DailyWorkContent from "./DailyWorkContent";
import { responsiveClassName } from "../../../../../../../utils/responsive";
import DivInteractive from "../../../../DivInteractive";

export default function DailyWork() {
    const [popupOpen, setPopupOpen] = useState(false);
    const [dailyWorkGroup, setDailyWorkGroup] = useState({});
    const currentProject = useCurrentProject();

    const closePopup = () => setPopupOpen(false);
    const openPopup = (dailyWorkGroup) => {
        setDailyWorkGroup(dailyWorkGroup);
        setPopupOpen(true);
    };

    const pipSummary = currentProject.projectStatusHistory[4].details.summary;
    const pipWorkItems = [];
    pipSummary.forEach((workItem) => {
        const { endDateTimeLocal } = workItem;
        pipWorkItems.push({
            title: workItem.title,
            details: [workItem.copy],
            endDateTimeLocal: endDateTimeLocal
                ? new Date(workItem.endDateTimeLocal)
                : endDateTimeLocal,
        });
    });

    const { estimatedCompletionDate } =
        TProject.getProjectDetails(currentProject);

    /**
     * @type {TDailyWorkGroup[]}
     */
    const dailyWork = [
        {
            alias: EDailyWorkGroup.WhatToExpect,
            title: {
                short: `${EDailyWorkGroup.WhatToExpect}.`,
                long: "What You Can Expect From Us During Your Project",
            },
            description:
                "Throughout your project, we'll keep you informed every step of the way.",
            dailyWorkItems: [
                {
                    title: "On the Way Call",
                    details: [
                        "Impression Matters: When our team is en route, we'll give you a call to introduce ourselves and ensure a smooth start.",
                        "Timely Arrival: You'll receive a call specifying our expected arrival time to eliminate any uncertainty.",
                    ],
                },
                {
                    title: "Pre-Job Walkthrough",
                    details: [
                        "Introduction: Upon arrival, we'll promptly introduce ourselves and our team, clarify roles, and provide a clear picture of our mission.",
                        "Parking Precision: Our trucks will be parked in the designated area for your convenience.",
                        "Work Details: We'll thoroughly discuss the scope of work to ensure a shared understanding.",
                        "Expectation Management: If you have any concerns, we'll address them head-on and set realistic expectations.",
                        "Invoice Insight: Before commencing work, we'll review the invoice to confirm balances and payment methods.",
                    ],
                },
                {
                    title: "Progress Updates",
                    details: [
                        "Daily Check-Ins: You'll receive regular updates throughout the day, keeping you informed about our progress.",
                        "Mid-Day and End-of-Day Reports: We'll provide precise details on what's done, what's in progress, and any adjustments to our schedule.",
                        "Your Preference: We value your communication style, so we'll ask how you'd like to stay updated.",
                    ],
                },

                {
                    title: "Post-Job Walkthrough",
                    details: [
                        "Thorough Cleanup: We leave no stone unturned, ensuring a spotless work area. All tools and materials are returned to their rightful places.",
                        "Final Job Review: We'll walk you through the finished work, ensuring it aligns with your expectations.",
                        "Your Satisfaction: Your feedback matters. We'll ask for your input to ensure you're completely satisfied.",
                    ],
                },
            ],
            buttonLabel: "See the Details",
        },
        {
            alias: EDailyWorkGroup.WhatWeWillBeDoing,
            title: {
                short: `${EDailyWorkGroup.WhatWeWillBeDoing}.`,
                long: "Tasks We'll Be Completing For Your Project",
            },
            description: "Here are the tasks we'll be completing:",
            note:
                !estimatedCompletionDate &&
                "There is more work to be scheduled.",
            dailyWorkItems: pipWorkItems,
            buttonLabel: "See Full Task Explanations",
        },
    ];

    return (
        <div
            style={{
                display: "flex",
                gap: "14px 14px" /* 20px horizontal gap, vertical gap */,
                flexWrap: "wrap",
            }}
        >
            {dailyWork.map((dailyWorkGroup, index) => {
                const hasTasks = !!dailyWorkGroup.dailyWorkItems?.length;
                return (
                    <div
                        key={`dailyWorkGroup_${index.toString()}`}
                        className={`workflow-card ${responsiveClassName(
                            "daily-work-group"
                        )} ${!hasTasks && "daily-work-group-no-button"}`}
                    >
                        <DailyWorkContent dailyWorkGroup={dailyWorkGroup} />
                        {hasTasks && (
                            <DivInteractive
                                className="daily-work-button gotham-book-normal-black-16px"
                                onClick={() => openPopup(dailyWorkGroup)}
                                onKeyUp={() => false}
                            >
                                {dailyWorkGroup.buttonLabel}
                            </DivInteractive>
                        )}
                    </div>
                );
            })}
            <DailyWorkPopup
                popupOpen={popupOpen}
                closePopup={closePopup}
                dailyWorkGroup={dailyWorkGroup}
            />
        </div>
    );
}
