import { useDispatch } from "react-redux";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { ActionType } from "../Redux/actions";

function useErrorHandler() {
    const dispatch = useDispatch();
    const appInsights = useAppInsightsContext();

    /**
     *
     * @param error {Error}
     * @param description {String}
     * @param exceptionType {String}
     */
    const handleError = (
        error,
        description,
        exceptionType,
        exceptionProperties
    ) => {
        const errorMessage = error.message;

        /* log error to console */
        console.error(`ERROR:${description || ""}\n${errorMessage}`);
        console.error(error.stack);

        let errorName = error.name;
        if (exceptionType) errorName = exceptionType;
        if (errorName === "Error") errorName = "Unknown Type";
        error.name = errorName;

        appInsights.trackException({
            exception: error,
            properties: exceptionProperties,
            severityLevel: SeverityLevel.Error,
        });

        /* set error in global state */
        dispatch({
            type: ActionType.SET_ERROR,
            description: description || errorMessage,
            message: errorMessage,
        });
    };

    return {
        handleError: handleError,
    };
}

export default useErrorHandler;
