import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import "./UserLoadErrorModal.css";
import { responsiveClassName } from "../../../../utils/responsive";
import Circle from "../../../../ErrorHandling/Circle";
import Button from "../../../Button/Button";
import Control from "../../../../Controls/Control";
import { useLogout } from "../../../../utils/hooks";

const propTypes = {
    popupOpen: PropTypes.bool,
    closePopup: PropTypes.func,
};

const defaultProps = {
    popupOpen: false,
    closePopup: null,
};

/**
 * @param {{popupOpen: boolean, closePopup: Function, dailyWorkGroup: TDailyWorkGroup}} props
 */
export default function UserLoadErrorModal({ popupOpen, closePopup }) {
    const { logout } = useLogout();

    return (
        <Modal
            show={popupOpen}
            onHide={closePopup}
            dialogClassName={`responsiveClassName("cp-modal-dialog") user-load-error-dialog`}
            contentClassName="cp-modal-content user-load-error"
            centered
        >
            <Modal.Body
                className={`${responsiveClassName(
                    "modal-body"
                )} user-load-error-body`}
            >
                <div className="user-load-error-title">
                    <div style={{ width: "52px" }}>
                        <Circle className="user-load-error-icon" />
                    </div>
                    <div className="gotham-bold-black-24px">
                        Oops! It looks like there is an error with your portal
                        account.
                    </div>
                </div>
                <div className="gotham-book-normal-black-20px user-load-error-message">
                    We apologize for this issue. Please come back again in about
                    30 minutes. If you are still having issues, please email
                    your support team at <Control.Link.CustomerSupport />.
                </div>
                <div className="user-load-error-buttons">
                    {" "}
                    <Button
                        text="Log Out"
                        onClick={logout}
                        className="main-button-sign-out"
                    />
                    <Button
                        text="Email Support Team"
                        linkTo="mailto:customersupport@groundworks.com"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

UserLoadErrorModal.propTypes = propTypes;
UserLoadErrorModal.defaultProps = defaultProps;
