import React from "react";
import LoginButton from "../Authentication/LoginButton";

export default function Home() {
    return (
        <div>
            <div>You are logged out.</div>
            <div>
                This temporary landing page will be replaced by the Groundworks
                Home Page when the URL is available.
            </div>
            <div>
                <LoginButton />
            </div>
        </div>
    );
}
