import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import "./ProgressBar.css";
import { EProgressMode } from "./enums";

const propTypes = {
    mode: PropTypes.string,
    progress: PropTypes.number,
};

const defaultProps = {
    mode: null,
    progress: 0.5,
};

export default function ProgressBar({ mode, progress }) {
    const { isAuthenticated } = useAuth0();
    const divGradient = useRef();

    const position = [0, progress * 100, 100];
    let direction = +1;

    const cssPrimary = "--color-primary";
    const cssSecondary = "--color-secondary";

    const backgroundGradient = () =>
        `linear-gradient(to right, var(${cssPrimary}) ${position[0]}%, var(${cssSecondary}) ${position[1]}%, var(${cssPrimary}) ${position[2]}%`;

    const move = () => {
        const div = divGradient.current;
        if (!div) return;

        if (direction * position[0] > 100) direction = -direction;

        for (let positionIdx = 0; positionIdx < 3; positionIdx++)
            position[positionIdx] += direction * 5;
        div.style.background = backgroundGradient();
    };

    const startMotion = () => {
        setInterval(move, 80);
    };

    const isStatic = mode !== EProgressMode.Loading;

    useEffect(() => {
        if (isStatic) return;
        if (isAuthenticated) startMotion();
    }, []);

    let progressCoverSuffix = isStatic ? "static" : "loading";
    if (progress) progressCoverSuffix = "progress";

    return (
        <div className={`progress progress-${isStatic ? "static" : "loading"}`}>
            <div
                ref={divGradient}
                className="progress-gradient"
                style={{
                    background: backgroundGradient(),
                }}
            >
                &nbsp;
            </div>
            {isStatic && (
                <>
                    <div
                        className={`progress-block progress-block-${
                            isStatic ? "static" : "loading"
                        }`}
                        style={{
                            marginLeft: isStatic && `${progress * 100}%`,
                            width:
                                isStatic &&
                                `calc(${(1 - progress) * 100}% + 1px`,
                        }}
                    >
                        &nbsp;
                    </div>
                    <div
                        className={`progress-cover progress-cover-${progressCoverSuffix}`}
                        style={{
                            marginLeft: isStatic && `${progress * 100}%`,
                            width: isStatic && `calc(${(1 - progress) * 100}%`,
                        }}
                    >
                        &nbsp;
                    </div>
                </>
            )}
        </div>
    );
}

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;
