import React, { useEffect, useMemo } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import {
    ReactPlugin,
    AppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import PropTypes from "prop-types";
import Login from "./Authentication/Login";
import ErrorPage from "./ErrorHandling/ErrorPage";
import appConfig from "./appConfig.json";
import Main from "./Main/Main";
import Home from "./Home/Home";
import Loading from "./Loading";
import Logout from "./Logout/Logout";
import { ActionType } from "./Redux/actions";

const propTypes = {
    isAdminUser: PropTypes.bool,
};

const defaultProps = {
    isAdminUser: false,
};

function App({ isAdminUser }) {
    const dispatch = useDispatch();
    dispatch({ type: ActionType.SET_IS_ADMIN_USER, isAdminUser: isAdminUser });

    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
    const error = useSelector((state) => state.error);

    const browserHistory = createBrowserHistory({ basename: "" });
    const reactPlugin = new ReactPlugin();
    const appInsights = useMemo(
        () =>
            new ApplicationInsights({
                config: {
                    instrumentationKey:
                        process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: { history: browserHistory },
                    },
                },
            })
    );
    appInsights.loadAppInsights();

    const url = window.location.href;
    const isGwHomePage = url.indexOf("home") === url.length - 4;
    const isLogoutPage = url.indexOf("/logout?") > -1;

    /* for testing only */
    const bypassLogin = appConfig.TEST.BYPASS_LOGIN;

    useEffect(() => {
        if (
            !bypassLogin &&
            !isGwHomePage &&
            !isLogoutPage &&
            !appConfig.USE_LOGIN_PAGE &&
            !isLoading &&
            !isAuthenticated
        )
            loginWithRedirect();
    }, [isLoading, isAuthenticated]);

    return (
        <AppInsightsContext.Provider value={appInsights}>
            <div>
                <Router>
                    {(!bypassLogin && !isAuthenticated && (
                        <Routes>
                            <Route path="/" Component={Login} />
                            <Route path="/home" Component={Home} />
                            <Route path="/logout" Component={Logout} />
                            <Route path="/admin" Component={Login} />
                        </Routes>
                    )) ||
                        (error.isSet && (
                            <Routes>
                                <Route path="/" Component={ErrorPage} />
                                <Route path="/logout" Component={Logout} />
                                <Route path="/admin" Component={ErrorPage} />
                            </Routes>
                        )) || (
                            <Routes>
                                <Route path="/" Component={Main} />
                                <Route path="/main" Component={Main} />
                                <Route path="/home" Component={Home} />
                                <Route path="/loading" Component={Loading} />
                                <Route path="/logout" Component={Logout} />
                                <Route path="/admin" Component={Main} />
                            </Routes>
                        )}
                </Router>
            </div>
        </AppInsightsContext.Provider>
    );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
