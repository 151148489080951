import React, { useContext } from "react";
import PropTypes from "prop-types";
import { isDesktop, isMobile } from "react-device-detect";
import ProjectStateCompleted from "../ProjectStateCompleted/ProjectStateCompleted";
import { EProjectStatus } from "../../../../../../globals/globals";
import {
    TProject,
    TWorkflowItem,
    useCurrentProject,
} from "../../../../../../Redux/useGlobalState";
import "./ProjectStateLabel.css";
import ProjectStateContext from "../ProjectStateContext";
import Icon from "../Icon/Icon";

const propTypes = {
    isOpen: PropTypes.bool,
    project: PropTypes.instanceOf(Object),
    status: PropTypes.string,
    isHomeProtection: PropTypes.bool,
    isGrayed: PropTypes.bool,
};

const defaultProps = {
    isOpen: false,
    project: null,
    status: null,
    isHomeProtection: false,
    isGrayed: true,
};

/**
 * @typedef {Object} Props
 * @property {Boolean} isOpen
 * @property {TProject} project
 * @property {String} status
 * @property {Boolean} isHomeProtection
 * @property {Boolean} hasContent
 */

/**
 * @param {TProject} project
 * @returns {TWorkflowItem[]}
 */
export const getProjectWorkflow = (project) => {
    /**
     * @type {TWorkflowItem[]}
     */
    let workflow = null;

    project.projectStatusHistory?.forEach((statusHistoryItem) => {
        const { projectStatus } = statusHistoryItem;
        if (projectStatus.alias === EProjectStatus.ProjectPreparation) {
            workflow = statusHistoryItem.details.workflow;
        }
    });

    return workflow;
};

/**
 * @param {TWorkflowItem[]} workflow
 * @returns {boolean}
 */
export const isIncompleteWorkflow = (workflow) => {
    let isIncompleteWorkflow = false;

    workflow?.forEach((workflowItem) => {
        if (!workflowItem.isCompleted) {
            isIncompleteWorkflow = true;
        }
    });

    return isIncompleteWorkflow;
};

/**
 * @param {Props} props
 */
export default function ProjectStateLabel(props) {
    const { isOpen, project, status, isHomeProtection, isGrayed } = props;

    const currentProject = useCurrentProject();
    const workflow = getProjectWorkflow(currentProject);
    const incompleteWorkflow = isIncompleteWorkflow(workflow);

    const projectState = useContext(ProjectStateContext);
    const showIncomplete =
        currentProject.currentProjectStatus?.alias ===
            EProjectStatus.ProjectInProgress &&
        projectState.status === EProjectStatus.ProjectPreparation &&
        incompleteWorkflow;

    const isComplete = () => {
        if (isHomeProtection) return project.hasServicePlan;

        if (TProject.allComplete(project)) return true;

        if (status === EProjectStatus.ProjectComplete)
            return !!project.projectStatusHistory?.find((statusHistory) => {
                return statusHistory.details?.survey?.isCompleted;
            });

        return EProjectStatus.comesBefore(
            status,
            project.currentProjectStatus?.alias
        );
    };

    return (
        <div className="project-state-label">
            {(status === EProjectStatus.ContractSigned &&
                project.isCancelled && (
                    <Icon size="30px" textClassName="gotham-bold-black-22px" />
                )) || (
                <ProjectStateCompleted
                    isComplete={!showIncomplete && isComplete()}
                    isGrayed={isGrayed}
                    isHomeProtection={isHomeProtection}
                />
            )}
            <div
                className={`contract-signed ${
                    isMobile && "contract-signed-mobile"
                } ${
                    (isDesktop &&
                        (isOpen
                            ? "gotham-book-normal-cloud-burst-22px"
                            : "gotham-book-normal-black-22px")) ||
                    (isMobile &&
                        (isOpen
                            ? "gotham-book-normal-cloud-burst-16px"
                            : "gotham-book-normal-black-16px"))
                }`}
            >
                {(isHomeProtection && "Protect Your Home") ||
                    EProjectStatus.name(status)}
            </div>
        </div>
    );
}

ProjectStateLabel.propTypes = propTypes;

ProjectStateLabel.defaultProps = defaultProps;
