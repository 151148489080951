import React from "react";
import PropTypes from "prop-types";
import Spoc from "./Spoc";
import "./SpocButton.css";
import DivInteractive from "../Content/DivInteractive";

const propTypes = {
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func,
};

const defaultProps = {
    expanded: true,
    setExpanded: null,
};

export default function SpocButton({ expanded, setExpanded }) {
    return (
        <DivInteractive
            className={`spoc-button ${expanded && "spoc-button-expanded"}`}
            onClick={(!expanded && (() => setExpanded(true))) || undefined}
            onKeyUp={() => false}
        >
            <div className="spoc-button-contents">
                <div className="spoc-button-image fontawesome6pro-solid-normal-cloud-burst-26px">
                    
                </div>
                <div className="gotham-bold-black-16px">Need Help?</div>
            </div>
            <Spoc visible={expanded} setExpanded={setExpanded} />
        </DivInteractive>
    );
}

SpocButton.propTypes = propTypes;
SpocButton.defaultProps = defaultProps;
