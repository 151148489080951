import { createReducer } from "@reduxjs/toolkit";
import { ActionType } from "../actions";

const initialState = {
    Selected: false,
    SelectedPropertyId: 1,
};

const dashboardReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(ActionType.SCHEDULE, (state, action) => {
            state.Scheduled = action.scheduled;
        })
        .addCase(ActionType.SET_SELECTED_PROPERTY_ID, (state, action) => {
            state.SelectedPropertyId = action.PropertyId;
        });
});

export default dashboardReducer;
