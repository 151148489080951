export const EProjectStatus = {
    InspectionBooked: "InspectionBooked",
    InspectionComplete: "InspectionComplete",
    ContractSigned: "ContractSigned",
    ProjectPreparation: "ProjectPreparation",
    ProjectInProgress: "ProjectInProgress",
    ProjectComplete: "ProjectComplete",
    HomeProtection: "HomeProtection",
    name: (status) => {
        switch (status) {
            case EProjectStatus.InspectionBooked:
                return "Inspection Booked";
            case EProjectStatus.InspectionComplete:
                return "Inspection Complete";
            case EProjectStatus.ContractSigned:
                return "Contract Signed";
            case EProjectStatus.ProjectPreparation:
                return "Project Preparation";
            case EProjectStatus.ProjectInProgress:
                return "Project In Progress";
            case EProjectStatus.ProjectComplete:
                return "Project Complete";
            default:
                return null;
        }
    },
    comesBefore: (status1, status2) => {
        let comesBefore = null;
        [
            EProjectStatus.InspectionBooked,
            EProjectStatus.InspectionComplete,
            EProjectStatus.ContractSigned,
            EProjectStatus.ProjectPreparation,
            EProjectStatus.ProjectInProgress,
            EProjectStatus.ProjectComplete,
        ].forEach((status) => {
            if (comesBefore === null && status === status1) comesBefore = false;
            else if (comesBefore === false && status === status2)
                comesBefore = true;
        });

        return !!comesBefore;
    },
};

export const EAppointmentType = {
    Appointment: "Appointment",
    Service: "Service",
};

export const EProjectDocumentType = {
    Unknown: "Unknown",
    Proposal: "Proposal",
    SignedContract: "SignedContract",
    Invoice: "Invoice",
};

export const EMenuSelection = {
    YourProject: "YourProject",
    Offers: "Offers",
    AccountDetails: "AccountDetails",
};

export const ELogoMode = {
    Normal: "Normal",
    Loading: "Loading",
};
