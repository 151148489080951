import React from "react";
import "./Loading.css";
import Logo from "./Logo/Logo";
import { ELogoMode } from "./globals/globals";
import ProgressBar from "./ProgressBar/ProgressBar";
import { EProgressMode } from "./ProgressBar/enums";

export default function Loading() {
    return (
        <div className="loading">
            <Logo mode={ELogoMode.Loading} />
            <ProgressBar mode={EProgressMode.Loading} />
        </div>
    );
}
