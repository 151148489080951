import { useAuth0 } from "@auth0/auth0-react";
import useErrorHandler from "../ErrorHandling/useErrorHandler";
import appConfig from "../appConfig.json";
import { EErrorType } from "../ErrorHandling/EErrorType";

function TApiActionOptions() {
    this.noResponseData = false;
    this.allowStatusCodes = [];
    this.isAdminLookup = false;
}

const useAPIService = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { handleError } = useErrorHandler();

    /**
     * @param {string} uri
     * @param {Object} body
     * @param {Function} fnCallback
     * @param {string} method
     * @param {TApiActionOptions} options
     * @returns
     */
    const apiAction = async (uri, body, fnCallback, method, options = {}) => {
        /* for testing only */
        const bypassLogin = appConfig.TEST.BYPASS_LOGIN;
        const accessToken = !bypassLogin
            ? await getAccessTokenSilently()
            : null;

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_API_URL}${uri}`, {
                method: method,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                    "ngrok-skip-browser-warning": true,
                },
                body: body && JSON.stringify(body),
            });
            const responseStatus = response.status;
            if (responseStatus !== 200) {
                if (options.isAdminLookup) return { userId: 0, isActive: true };
                if (!options.allowStatusCodes?.includes(responseStatus)) {
                    throw new Error(
                        `API fetch returned ${responseStatus} status code.`
                    );
                }
            }
        } catch (e) {
            handleError(e, "Error in API service call", EErrorType.API, {
                accessToken: accessToken,
            });
            return;
        }

        let data = null;
        if (!options.noResponseData)
            try {
                data = await response.json();
            } catch (e) {
                handleError(e, "Error in API service JSON", EErrorType.API, {
                    accessToken: accessToken,
                });
                return;
            }

        if (fnCallback) fnCallback(data);
        return data;
    };

    const apiGet = async (uri, fnCallback, options) => {
        return apiAction(uri, null, fnCallback, "GET", options);
    };

    const apiPut = async (uri, body, fnCallback, options) => {
        return apiAction(uri, body, fnCallback, "PUT", options);
    };

    return {
        apiGet,
        apiPut,
    };
};

export default useAPIService;
