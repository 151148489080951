import React from "react";
import PropTypes from "prop-types";
import { isDesktop } from "react-device-detect";
import Button from "../../Button/Button";
import { useContact, useCurrentProject } from "../../../Redux/useGlobalState";
import { EProjectStatus } from "../../../globals/globals";
import DivInteractive from "../Content/DivInteractive";
import Control from "../../../Controls/Control";

const propTypes = {
    visible: PropTypes.bool,
    setExpanded: PropTypes.func,
};

const defaultProps = {
    visible: true,
    setExpanded: null,
};

export default function Spoc({ visible, setExpanded }) {
    const contact = useContact();
    const projectStatusAlias = useCurrentProject().currentProjectStatus?.alias;

    return (
        <div
            style={{
                backgroundColor: "var(--white)",
                padding: isDesktop
                    ? "15px 31px 15px 31px"
                    : "15px 16px 21px 22px",
                borderRadius: "14px",
                border: "none",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                position: isDesktop ? "static" : "absolute",
                width: "100%",
                height: isDesktop ? "auto" : "100%",
                visibility: visible ? "visible" : "hidden",
            }}
        >
            <div
                style={{
                    display: "flex",
                    gap: "21px",
                }}
            >
                <div className="gotham-bold-cloud-burst-20px">
                    {(contact.isEscalated &&
                        "Have Questions? Here's Your Support Contact") ||
                        (projectStatusAlias !== EProjectStatus.ProjectComplete
                            ? "Have Questions? Contact Your Production Team!"
                            : "Have Questions? Contact Your Service Team!")}
                </div>
                <DivInteractive
                    className="fontawesome6pro-solid-normal-abbey-24px mobile-only"
                    onClick={() => setExpanded(false)}
                    onKeyUp={() => false}
                >
                    
                </DivInteractive>
            </div>

            <div>
                <Button
                    style={{ padding: "0", backgroundColor: "transparent" }}
                    icon={
                        <div
                            className="fontawesome6pro-solid-normal-cloud-burst-33px"
                            style={{ color: "var(--allports)" }}
                        >
                            
                        </div>
                    }
                    text={
                        <Control.Phone.Contact
                            textClass={`gotham-book-normal-abbey-${"24"}px`}
                        />
                    }
                />
            </div>
        </div>
    );
}

Spoc.propTypes = propTypes;
Spoc.defaultProps = defaultProps;
