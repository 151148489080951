import React, { useEffect, useRef } from "react";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import contentSeparator from "../../../../../../static/img/line-8-1@1x.png";
import Foreman from "../Foreman/Foreman";
import {
    TProject,
    TProjectStatus,
    TWorkflowItem,
} from "../../../../../../Redux/useGlobalState";
import "./ProjectStateContent.css";

const propTypes = {
    projectStatus: PropTypes.instanceOf(Object),
    projectStateMessage: PropTypes.instanceOf(Object),
    foremanRow: PropTypes.instanceOf(Object),
    projectStateStamp: PropTypes.instanceOf(Object),
};

const defaultProps = {
    projectStatus: null,
    projectStateMessage: null,
    foremanRow: null,
    projectStateStamp: null,
};

/**
 * @param {Object} props
 * @param {TProjectStatus} props.projectStatus
 * @param {Date} props.estimatedStartDate
 * @param {Date} props.contractSignedDate
 * @param {bool} props.contractUploaded
 * @param {TWorkflowItem[]} props.workflow
 * @param {string} props.documentUrl
 * @param {string} props.status
 */
export default function ProjectPreparationContent(props) {
    const {
        projectStatus,
        projectStateMessage,
        foremanRow,
        projectStateStamp,
    } = props;

    const projectStatusAlias = projectStatus?.alias;

    const fixedColumnWidthPx =
        (projectStatusAlias === "ProjectPreparation" && 160) || 270;

    const fixLeftColumn = projectStatusAlias === "ProjectPreparation";

    const refContentLeft = useRef();
    const refContentRight = useRef();
    const isStacked = () => {
        const divLeft = refContentLeft.current;
        const divRight = refContentRight.current;
        if (divLeft && divRight) {
            divRight.style.margin = `0 0 0 ${
                divLeft.offsetTop < divRight.offsetTop ? "9px" : "0"
            }`;
            divRight.style.padding = `0 0 0 ${
                divLeft.offsetTop < divRight.offsetTop ? "0" : "15px"
            }`;
        }
    };

    useEffect(() => {
        window.addEventListener("resize", isStacked);
        isStacked();
    }, []);

    return (
        <div className="project-state-content">
            <div
                style={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    height: isDesktop ? "20px" : "15px",
                    marginTop: isMobile && "8px",
                }}
            >
                <img
                    className="project-state-content-separator"
                    src={contentSeparator}
                    alt="Project State Content Separator"
                />
            </div>
            {isMobile && foremanRow}
            <div className="container project-state-content-container">
                <div
                    className="row"
                    style={{
                        left: 0,
                    }}
                >
                    <div
                        ref={refContentLeft}
                        id="divContentLeft"
                        className="col-sm-6"
                        style={{
                            minWidth: !fixLeftColumn ? "326px" : undefined,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width:
                                (isDesktop &&
                                    (fixLeftColumn
                                        ? `${fixedColumnWidthPx}px`
                                        : `calc(100% - ${
                                              fixedColumnWidthPx + 10
                                          }px`)) ||
                                "100%",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                            }}
                        >
                            {isDesktop && projectStateStamp}
                            {isDesktop && <Foreman label />}
                        </div>
                    </div>
                    {isBrowser && (
                        <div
                            ref={refContentRight}
                            id="divContentRight"
                            className="col-sm-6"
                            style={{
                                minWidth: fixLeftColumn ? "350px" : undefined,
                                width: fixLeftColumn
                                    ? `calc(100% - ${fixedColumnWidthPx + 12}px`
                                    : `${fixedColumnWidthPx}px`,
                            }}
                        >
                            {projectStateMessage}
                        </div>
                    )}
                    {isMobile && (
                        <div style={{ marginTop: "5px" }}>
                            {projectStateMessage}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

ProjectPreparationContent.propTypes = propTypes;
ProjectPreparationContent.defaultProps = defaultProps;
