import React from "react";
import LoginButton from "./LoginButton";
import appConfig from "../appConfig.json";
import Loading from "../Loading";

export default function Login() {
    return (
        <div>
            {(!appConfig.USE_LOGIN_PAGE && <Loading />) || (
                <LoginButton>Log in</LoginButton>
            )}
        </div>
    );
}
