import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    alt: PropTypes.string,
};

const defaultProps = {
    alt: "",
};

const ImgInteractive = React.forwardRef((props, imgRef) => {
    return <img ref={imgRef} alt={props.alt} {...props} />;
});

export default ImgInteractive;

ImgInteractive.propTypes = propTypes;
ImgInteractive.defaultProps = defaultProps;
