import { createReducer } from "@reduxjs/toolkit";
import { TOffer } from "../useGlobalState";
import { ActionType } from "../actions";

/**
 * @type {TOffer[]}
 */
const initialState = [];

const offersReducer = createReducer(initialState, (builder) => {
    builder.addCase(ActionType.SET_OFFERS, (state, action) => {
        const { offers } = action;

        offers.forEach((offer, offerIndex) => {
            state[offerIndex] = offer;
        });
    });
});

export default offersReducer;
