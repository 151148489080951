import { ELogoMode, EMenuSelection } from "../globals/globals";

const divClassName = (isMobile, mode, menuSelection, setHeightMobile) => {
    return `navigation-logo ${
        isMobile &&
        mode !== ELogoMode.Loading &&
        `navigation-logo-mobile ${
            menuSelection === EMenuSelection.YourProject &&
            "navigation-logo-mobile-project"
        } ${
            [EMenuSelection.Offers, EMenuSelection.AccountDetails].includes(
                menuSelection
            ) && "navigation-logo-mobile-offers"
        } ${isMobile && setHeightMobile && "navigation-logo-mobile-height"}`
    } ${
        mode === ELogoMode.Loading && "navigation-logo-loading"
    } gotham-book-normal-black-16px `;
};

const imgClassName = (setHeight, isMobile, setHeightMobile, mode) => {
    return `navigation-logo ${setHeight && "navigation-logo-height"}  ${
        isMobile &&
        `navigation-logo-mobile ${
            setHeightMobile && "navigation-logo-mobile-height"
        }`
    } ${mode === ELogoMode.Loading && "navigation-logo-loading"}`;
};

export { divClassName, imgClassName };
