import React from "react";
import PropTypes from "prop-types";
import Loading from "../Loading";
import { sessionDuration } from "../Redux/useGlobalState";
import LogoutLink from "../Authentication/LogoutLink";
import AccountNotReady from "./AccountNotReady/AccountNotReady";

const propTypes = {
    userIsActive: PropTypes.bool,
    projectNotAvailable: PropTypes.bool,
};

const defaultProps = {
    userIsActive: null,
    projectNotAvailable: null,
};

export default function UserLoading(props) {
    const { userIsActive, projectNotAvailable } = props;

    /* keep this code for testing - will be removed at a later date
    const indicateProgress = (progress) => {
        let result = "";
        while (progress--) {
            result += " \u2022";
        }
        return result;
    };
    */

    return (
        <>
            {
                !userIsActive &&
                    !projectNotAvailable &&
                    ((sessionDuration() < 0 && (
                        <div>
                            <div>Please wait...</div>
                            <LogoutLink /> to log out
                        </div>
                    )) || <Loading />)
                /* keep this code for testing - will be removed at a later date
                     User information is loading
                     {indicateProgress(progress)}
                </div>
                */
            }
            {projectNotAvailable && <AccountNotReady />}
        </>
    );
}

UserLoading.propTypes = propTypes;
UserLoading.defaultProps = defaultProps;
