import { createReducer } from "@reduxjs/toolkit";
import { ActionType } from "../actions";
import { EMenuSelection } from "../../globals/globals";

const initialState = {
    menuSelection: EMenuSelection.YourProject,
    sessionStart: new Date().getTime(),
    isAdminUser: false,
    selectedProjectId: null,
    customerNotFound: false,
    selectedProjectStatus: null,
};

const userSessionReducer = createReducer(initialState, (builder) => {
    builder.addCase(ActionType.SET_MENU_SELECTION, (state, action) => {
        state.menuSelection = action.menuSelection;
    });
    builder.addCase(ActionType.SET_IS_ADMIN_USER, (state, action) => {
        state.isAdminUser = action.isAdminUser;
    });
    builder.addCase(ActionType.SET_SELECTED_PROJECT_ID, (state, action) => {
        state.selectedProjectId = action.selectedProjectId;
    });
    builder.addCase(ActionType.SET_CUSTOMER_NOT_FOUND, (state, action) => {
        state.customerNotFound = action.customerNotFound;
    });
    builder.addCase(ActionType.SET_SELECTED_STATUS, (state, action) => {
        state.selectedProjectStatus = action.selectedProjectStatus;
    });
});

export default userSessionReducer;
