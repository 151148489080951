import React from "react";
import Project from "./Project/Project";
import "./Content.css";
import { useUserSession } from "../../../Redux/useGlobalState";
import Offers from "./Offers/Offers";
import { EMenuSelection } from "../../../globals/globals";
import AccountDetails from "./AccountDetails/AccountDetails";
import ContentFrame from "./ContentFrame/ContentFrame";

export default function Content() {
    const { menuSelection } = useUserSession();

    return (
        (menuSelection === EMenuSelection.YourProject && <Project />) ||
        (menuSelection === EMenuSelection.Offers && <Offers />) ||
        (menuSelection === EMenuSelection.AccountDetails && (
            <ContentFrame title="Account Details" body={<AccountDetails />} />
        ))
    );
}
