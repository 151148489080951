import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import imgAnonymous from "../../../static/img/anonymous.png";
import { useUser } from "../../../Redux/useGlobalState";
import ImgInteractive from "./ImgInteractive";
import ProjectStateContext from "../Content/Project/ProjectState/ProjectStateContext";
import "./EmployeePhoto.css";

const propTypes = {
    photoUrl: PropTypes.string,
    anonymousImageEnabled: PropTypes.bool,
};

const defaultProps = {
    photoUrl: null,
    anonymousImageEnabled: false,
};

const displayAnonymousImage = (anonymousImageEnabled, photoError, user) => {
    return !(!anonymousImageEnabled && (photoError || user.userId === 0));
};

const imageInteractive = (
    imgPhoto,
    photoError,
    user,
    photoUrl,
    fitImage,
    setPhotoError
) => {
    return (
        <ImgInteractive
            ref={imgPhoto}
            className="foreman-img-width"
            src={photoError || user.userId === 0 ? imgAnonymous : photoUrl}
            alt="Not Available"
            onLoad={() => {
                fitImage();
            }}
            style={{ visibility: "hidden", position: "absolute" }}
            onError={() => {
                setPhotoError(true);
            }}
        />
    );
};

export default function EmployeePhoto({ photoUrl, anonymousImageEnabled }) {
    const [imageFitted, setImageFitted] = useState(false);

    const user = useUser();
    const [photoError, setPhotoError] = useState(false);

    const projectStateContext = useContext(ProjectStateContext);

    const divPhoto = useRef();
    const divLoading = useRef();
    const imgPhoto = useRef();

    const fitImage = (setFit) => {
        if (setFit && imageFitted) return;

        const div = divPhoto.current;
        const img = imgPhoto.current;

        if (img.offsetWidth > img.offsetHeight) {
            img.className = "foreman-img-height";
            img.style.marginLeft = `-${Math.floor(
                (img.offsetWidth - div.offsetWidth) / 2
            )}px`;
        } else {
            img.className = "foreman-img-width";
            img.style.marginLeft = "0";
        }

        divLoading.current.style.visibility = "hidden";
        imgPhoto.current.style.visibility = "visible";

        if (setFit) setImageFitted(true);
    };

    if (projectStateContext)
        projectStateContext.fitImage = () => {
            setTimeout(() => fitImage(true), 50);
        };

    /**
     * @param {string} photoUrl
     * @returns {string}
     */
    const cleanUpUrl = (photoUrl) => {
        const REPLACE_STRING = "UserImages/";
        const replaceLength = REPLACE_STRING.length;

        if (!photoUrl) return photoUrl;

        const fileNamePos = photoUrl.lastIndexOf("/") + 1;
        const replacePos = fileNamePos - replaceLength;

        if (
            photoUrl
                .toLowerCase()
                .substring(replacePos, replacePos + replaceLength) !==
            REPLACE_STRING.toLowerCase()
        )
            return photoUrl;

        return `${photoUrl.substring(0, replacePos)}${photoUrl.substring(
            fileNamePos
        )}`;
    };

    photoUrl = cleanUpUrl(photoUrl);

    return displayAnonymousImage(anonymousImageEnabled, photoError, user) ? (
        <div ref={divPhoto} className="foreman-photo-div">
            <div
                ref={divLoading}
                className="gotham-book-normal-black-16px employee-photo-loading"
            >
                loading...
            </div>
            {imageInteractive(
                imgPhoto,
                photoError,
                user,
                photoUrl,
                fitImage,
                setPhotoError
            )}
        </div>
    ) : null;
}

EmployeePhoto.propTypes = propTypes;
EmployeePhoto.defaultProps = defaultProps;
