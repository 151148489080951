import { isMobile } from "react-device-detect";

const responsiveClassName = (rootClassName) => {
    let className = rootClassName;
    if (isMobile) className += ` ${rootClassName}-mobile`;
    return className;
};

/* eslint-disable */
export { responsiveClassName };
