import React from "react";
import "./PdfIcon.css";

export default function PdfIcon() {
    return (
        <div className="check-active">
            <div className="fontawesome6pro-solid-normal-feijoa-14px file-pdf">
                file-pdf
            </div>
        </div>
    );
}
