import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./reducers/dashboardReducer";
import userReducer from "./reducers/userReducer";
import errorReducer from "./reducers/errorReducer";
import currentProjectReducer from "./reducers/currentProjectReducer";
import userSessionReducer from "./reducers/userSessionReducer";
import offersReducer from "./reducers/offersReducer";
import currentAddressReducer from "./reducers/currentAddressReducer";
import contactReducer from "./reducers/contactReducer";
import notificationsReducer from "./reducers/notificationsReducer";

const store = configureStore({
    reducer: {
        error: errorReducer,
        dashboard: dashboardReducer,
        user: userReducer,
        offers: offersReducer,
        currentProject: currentProjectReducer,
        contact: contactReducer,
        notifications: notificationsReducer,
        currentAddress: currentAddressReducer,
        userSession: userSessionReducer,
    },
});

export default store;
