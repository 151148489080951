import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CheckActive3 from "../PdfIcon/PdfIcon";
import "./ViewContract.css";
import { EProjectStatus } from "../../../../../../globals/globals";

const propTypes = {
    documentUrl: PropTypes.string,
    status: PropTypes.string,
};

const defaultProps = {
    documentUrl: null,
    status: null,
};

export default function ViewContract(props) {
    const { documentUrl, status } = props;

    return (
        status === EProjectStatus.ContractSigned && (
            <div className="view-contract">
                <Link to={documentUrl} target="_blank">
                    <div style={{ position: "absolute", top: "10px" }}>
                        <CheckActive3 />
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            left: "55px",
                            top: "13px",
                        }}
                    >
                        <div className="view-signed-contract gotham-book-normal-cloud-burst-16px">
                            View Signed Contract
                        </div>
                    </div>
                </Link>
            </div>
        )
    );
}

ViewContract.propTypes = propTypes;
ViewContract.defaultProps = defaultProps;
