import React from "react";
import "./Notification.css";
import PropTypes from "prop-types";
import Icon from "../../Icon/Icon";
import Note from "../Note/Note";
import {
    TNotification,
    useNotifications,
} from "../../../../../../../Redux/useGlobalState";
import { ENotificationType } from "./eNotificationType";
import { useActions } from "../../../../../../../utils/hooks";

const propTypes = {
    notificationTypeAlias: PropTypes.string,
    messagePrefix: PropTypes.string,
    messagePrefixClassName: PropTypes.string,
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Object),
    ]),
    messageClassName: PropTypes.string,
    className: PropTypes.string,
    iconText: PropTypes.string,
    iconTextClassName: PropTypes.string,
    iconSize: PropTypes.string,
    iconFilled: PropTypes.bool,
    dismissIcon: PropTypes.bool,
};

const defaultProps = {
    notificationTypeAlias: null,
    messagePrefix: null,
    messagePrefixClassName: null,
    message: "(notification message)",
    messageClassName: null,
    className: "",
    iconText: "!",
    iconTextClassName: "gotham-bold-black-18px",
    iconSize: "24px",
    iconFilled: false,
    dismissIcon: true,
};

export default function Notification({
    notificationTypeAlias,
    messagePrefix,
    messagePrefixClassName,
    message,
    messageClassName,
    className,
    iconText,
    iconTextClassName,
    iconSize,
    iconFilled,
    dismissIcon,
}) {
    const { dismissNotification } = useActions();

    let notification = null;

    /**
     *
     * @param {TNotification[]} notifications
     */
    const showNotification = (notifications) => {
        let showNotification = false;
        notifications?.forEach((nf) => {
            if (
                nf.notificationTypeAlias ===
                ENotificationType.ProjectStartDateChanged
            ) {
                showNotification = true;
                notification = nf;
            }
        });
        return showNotification;
    };

    let notifications = useNotifications();
    notifications = notifications.filter(
        (notification) =>
            notification.notificationTypeAlias === notificationTypeAlias
    );

    if (!showNotification(notifications)) return null;

    return (
        <div
            className={`notification ${
                iconFilled && "notification-icon-filled"
            } ${className}`}
        >
            <div className="notification-content">
                <Icon
                    backgroundColor="var(--orange)"
                    border="none"
                    text={iconText}
                    textClassName={iconTextClassName}
                    size={iconSize}
                    filled={iconFilled}
                />
                <div
                    style={{
                        marginLeft: iconFilled && `calc(${iconSize} + 12px)`,
                        marginTop: iconFilled && "6px",
                        marginBottom: iconFilled && "6px",
                    }}
                >
                    <Note
                        notePrefix={messagePrefix}
                        prefixClassName={messagePrefixClassName}
                        noteText={
                            typeof message === "function"
                                ? message(notification)
                                : notification.notificationText || message
                        }
                        textClassName={messageClassName}
                    />
                </div>
            </div>
            {dismissIcon && (
                <div>
                    <Icon
                        border="none"
                        text=""
                        textClassName="fontawesome6pro-solid-normal-black-24px"
                        onClick={() => dismissNotification(notification)}
                    />
                </div>
            )}
        </div>
    );
}

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;
