import React, { useEffect, useRef } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import "./ContentFrame.css";
import ViewOtherProjects from "../../../../Controls/ViewOtherProjects";
import ContentSeparator from "../ContentSeparator/ContentSeparator";
import Address from "../../Property/ProjectDocuments/Address/Address";
import {
    useCurrentProject,
    useUserSession,
} from "../../../../Redux/useGlobalState";
import { EMenuSelection, EProjectStatus } from "../../../../globals/globals";
import DivInteractive from "../DivInteractive";
import { useLogout } from "../../../../utils/hooks";

const propTypes = {
    title: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const defaultProps = {
    title: "(untitled)",
    body: "(no body)",
};

export default function ContentFrame({ title, body }) {
    const divBody = useRef();
    const currentProject = useCurrentProject();
    const { logout } = useLogout();
    const userSession = useUserSession();

    const setScrollMargin = () => {
        if (!isDesktop) return;
        const divTarget = divBody.current;
        if (!divTarget) return;
        const isScrolling = divTarget.clientHeight < divTarget.scrollHeight;
        console.log("setScrollMargin");
        divTarget.style.paddingRight = `calc(58px${
            isScrolling ? " - 15px" : ""
        })`;
    };

    window.addEventListener("resize", () => setScrollMargin(), true);
    useEffect(() => setScrollMargin(), []);

    return (
        <div className="content-frame">
            <div className="content-frame-header">
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "baseline",
                        gap: "5px 20px",
                    }}
                >
                    <div className="gotham-bold-cloud-burst-33px content-frame-header-title">
                        {title}
                    </div>
                    <div
                        style={{
                            whiteSpace: "nowrap",
                            marginLeft: "auto",
                        }}
                    >
                        {currentProject.currentProjectStatus?.alias ===
                            EProjectStatus && <ViewOtherProjects />}
                        {isMobile &&
                            userSession.menuSelection ===
                                EMenuSelection.AccountDetails && (
                                <DivInteractive
                                    className="content-frame-header-logout"
                                    onClick={() => logout(true)}
                                    onKeyUp={() => false}
                                >
                                    <div className="fontawesome6prosolid-solid-normal-abbey-18px">
                                        
                                    </div>
                                    <div className="gotham-book-normal-abbey-18px">
                                        Log Out
                                    </div>
                                </DivInteractive>
                            )}
                    </div>
                </div>
                <div className="content-frame-header-separator">
                    <ContentSeparator />
                </div>
                <div className="content-frame-address">
                    <Address />
                </div>
            </div>
            <div ref={divBody} className="content-frame-body">
                {body}
            </div>
        </div>
    );
}

ContentFrame.propTypes = propTypes;
ContentFrame.defaultProps = defaultProps;
