import React, { useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLogout, useQueryString } from "../utils/hooks";
import "./Logout.css";

export default function Logout() {
    const { isAuthenticated } = useAuth0();
    const { logout } = useLogout();

    if (isAuthenticated) logout();

    const redirectUrl = useQueryString().get("redirectUrl");

    if (redirectUrl) window.location.replace(redirectUrl);

    const divLogout = useRef();

    useEffect(() => {
        setTimeout(() => {
            divLogout.current.classname = "logout-display";
        }, 2000);
    }, []);

    return (
        <div id="divLogout" ref={divLogout} className="logout-no-display">
            <div>You are logged out.</div>
        </div>
    );
}
