import { createReducer } from "@reduxjs/toolkit";
import { ActionType } from "../actions";

const initialState = {};

const currentProjectReducer = createReducer(initialState, (builder) => {
    builder.addCase(ActionType.SET_CURRENT_PROJECT, (state, action) => {
        const { currentProject } = action;
        for (const key in currentProject) state[key] = currentProject[key];
    });
});

export default currentProjectReducer;
