import React from "react";
import PropTypes from "prop-types";
import "./Logo.css";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useBrand, useUserSession } from "../Redux/useGlobalState";
import { ELogoMode } from "../globals/globals";
import Brands from "../brands/brands.json";
import { divClassName, imgClassName } from "./logoClassNames";

const propTypes = {
    mode: PropTypes.string,
};

const defaultProps = {
    mode: ELogoMode.Normal,
};

const defaultLogoUrl = "/brand_logos/groundworks_logo.png";

export default function Logo({ mode }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const qsBrandId = queryParams.get("brandId");
    const brand = useBrand();
    const brandId = brand?.id;
    const brandConfig = Brands[qsBrandId ? parseInt(qsBrandId) : brandId];
    const brandLogoUrl = brandConfig?.logoUrl;
    const setHeight = brandConfig?.setHeight;
    const setHeightMobile = brandConfig?.setHeightMobile;
    const logoUrl =
        (mode === ELogoMode.Loading && defaultLogoUrl) ||
        (brandLogoUrl && `${brandLogoUrl}?width=450`) ||
        (!brandId && defaultLogoUrl) ||
        `/brands/logos/brand_${brandId}.webp`;

    const strShowVersionInfo = queryParams.get("showVersionInfo");
    const showVersionInfo = strShowVersionInfo === "true";

    const { menuSelection } = useUserSession();

    return (
        <div
            className={divClassName(
                isMobile,
                mode,
                menuSelection,
                setHeightMobile
            )}
        >
            {(logoUrl && (
                <img
                    className={imgClassName(
                        setHeight,
                        isMobile,
                        setHeightMobile,
                        mode
                    )}
                    // alt="brand logo"
                    alt={logoUrl}
                    src={logoUrl}
                />
            )) ||
                brand.name}
            {showVersionInfo && (
                <>
                    <div>{process.env.REACT_APP_ENVIRONMENT_NAME}</div>
                    <div>Build No: {process.env.BUILDNO}</div>
                    <div>NODE_ENV: {process.env.NODE_ENV}</div>
                </>
            )}
        </div>
    );
}

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;
