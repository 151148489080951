import React from "react";
import { Link } from "react-router-dom";
import {
    TServicePlan,
    useBrand,
    useCurrentAddress,
    useCurrentProject,
} from "../../../../../../Redux/useGlobalState";
import Button from "../../../../../Button/Button";
import "./Survey.css";
import googleIcon from "../../../../../../static/img/google_icon.svg";
import facebookIcon from "../../../../../../static/img/facebook_icon.svg";
import yelpIcon from "../../../../../../static/img/yelp_icon.svg";
import appConfig from "../../../../../../appConfig.json";
import { responsiveClassName } from "../../../../../../utils/responsive";

export default function Survey(props) {
    const currentProject = useCurrentProject();
    const currentAddress = useCurrentAddress();
    const brand = useBrand();
    const { projectId } = currentProject;

    /**
     * @type {TServicePlan}
     */
    let surveyComplete = false;
    let surveyUrl = null;
    let surveyRank = null;
    currentProject.projectStatusHistory.forEach((statusHistoryItem) => {
        if (
            statusHistoryItem.projectStatus.alias === "ProjectComplete" ||
            statusHistoryItem.projectStatus.alias === "HomeProtection"
        ) {
            const { survey } = statusHistoryItem.details;
            surveyComplete = survey.isCompleted;
            surveyUrl = survey.url;
            surveyRank = survey.rank;
        }
    });

    /* will enable this when we get the links from the api */
    const showLinks = false;

    return (
        <div className={responsiveClassName("survey")}>
            <div className="survey-header">
                {(!surveyComplete ||
                    [null, 6, 7, 8, 9, 10].includes(surveyRank)) && (
                    <div className="fontawesome6pro-solid-normal-yellow-40px">
                        
                    </div>
                )}
                <div className="survey-title gotham-bold-black-20px">
                    {!surveyComplete &&
                        `Thank you for choosing ${
                            currentAddress.brand?.name || "Groundworks"
                        } for your home repair needs.`}
                    {surveyComplete &&
                        `${"Thank you for submitting your feedback!"}`}
                </div>
            </div>
            <div className="survey-body gotham-book-normal-black-16px">
                {!surveyComplete &&
                    `We strive to provide best-in-class service. Can you take a moment to review your experience with ${
                        currentAddress.brand?.name || "Groundworks"
                    }?`}
                {surveyComplete &&
                    (([9, 10].includes(surveyRank) &&
                        "We value your thoughts and feedback. If you have time, please share your experience with other customers. We appreciate your time and trust.") ||
                        ([null, 6, 7, 8].includes(surveyRank) &&
                            "We appreciate the opportunity to work on your home. If you have any questions or concerns regarding your project, your support team is always standing by. Thank you for trusting Groundworks for your home repair needs!") ||
                        ([0, 1, 2, 3, 4, 5].includes(surveyRank) &&
                            `We're sorry to hear we didn't exceed your expectations. A member of our local team will be contacting you to discuss your project. Thank you for choosing ${
                                currentAddress.brand?.name || "Groundworks"
                            }; we will continue to work until it is right.`) ||
                        `${"If you have any other questions about your project, please reach out to your support team. We appreciate your service!"}`)}
            </div>
            {showLinks && (
                <div className="survey-links gotham-bold-black-16px">
                    <div>Share To:</div>
                    <Link to={appConfig.reviewUrl.GOOGLE} target="_blank">
                        <img
                            alt="Google"
                            src={googleIcon}
                            className="survey-review-icon"
                        />
                    </Link>
                    <Link to={appConfig.reviewUrl.FACEBOOK} target="_blank">
                        <img
                            alt="Facebook"
                            src={facebookIcon}
                            className="survey-review-icon"
                        />
                    </Link>
                    <Link to={appConfig.reviewUrl.YELP} target="_blank">
                        <img
                            alt="Yelp"
                            src={yelpIcon}
                            className="survey-review-icon"
                        />
                    </Link>
                </div>
            )}
            {!surveyComplete && (
                <div className="survey-button">
                    <Button
                        text="Take the Survey"
                        linkTo={`${surveyUrl}?projectId=${projectId}`}
                    />
                </div>
            )}
            {surveyComplete && [9, 10].includes(surveyRank) && (
                <div className="survey-button">
                    <Button
                        text="Share on Our Website"
                        linkTo={`${brand?.brandWebsiteUrl}/reviews`}
                    />
                </div>
            )}
        </div>
    );
}
