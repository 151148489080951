export const ActionType = {
    SET_ERROR: "SET_ERROR",
    SCHEDULE: "SCHEDULE",
    SET_PERSON: "SET_PERSON",
    SET_CURRENT_PROJECT: "SET_CURRENT_PROJECT",
    SET_CONTACT: "SET_CONTACT",
    SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
    SET_CURRENT_ADDRESS: "SET_CURRENT_ADDRESS",
    SET_SELECTED_PROPERTY_ID: "SET_SELECTED_PROPERTY_ID",
    ACCEPT_QUOTE: "ACCEPT_QUOTE",
    SET_FEATURE_OVERVIEW_COMPLETE: "FEATURE_OVERVIEW",
    SET_MENU_SELECTION: "SET_MENU_SELECTION",
    SET_CUSTOMER_NOT_FOUND: "SET_CUSTOMER_NOT_FOUND",
    SET_SELECTED_STATUS: "SET_SELECTED_STATUS",
    SET_OFFERS: "SET_OFFERS",
    SET_IS_ADMIN_USER: "SET_IS_ADMIN_USER",
    SET_SELECTED_PROJECT_ID: "SET_SELECTED_PROJECT_ID",
};
