import React from "react";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import contentSeparator from "../../../../../../static/img/line-8-1@1x.png";
import ProjectStateStamp from "../ProjectStateStamp";
import ViewContract from "../ViewContract/ViewContract";
import ProjectStateMessage from "../ProjectStateMessage/ProjectStateMessage";
import {
    TProject,
    TProjectStatus,
    TWorkflowItem,
} from "../../../../../../Redux/useGlobalState";
import { EProjectStatus } from "../../../../../../globals/globals";
import "./ProjectStateContent.css";

const propTypes = {
    projectStatus: PropTypes.instanceOf(Object),
    estimatedStartDate: PropTypes.instanceOf(Date),
    contractSignedDate: PropTypes.string,
    contractUploaded: PropTypes.bool,
    workflow: PropTypes.instanceOf(Object),
    documentUrl: PropTypes.string,
    status: PropTypes.string,
};

const defaultProps = {
    projectStatus: null,
    estimatedStartDate: null,
    contractSignedDate: null,
    contractUploaded: false,
    workflow: null,
    documentUrl: PropTypes.string,
    status: PropTypes.string,
};

/**
 * @param {Object} props
 * @param {TProjectStatus} props.projectStatus
 * @param {Date} props.estimatedStartDate
 * @param {Date} props.contractSignedDate
 * @param {bool} props.contractUploaded
 * @param {TWorkflowItem[]} props.workflow
 * @param {string} props.documentUrl
 * @param {string} props.status
 */
export default function ContractSignedContent(props) {
    const {
        projectStatus,
        estimatedStartDate,
        contractSignedDate,
        contractUploaded,
        workflow,
        documentUrl,
        status,
    } = props;

    const projectStatusAlias = projectStatus?.alias;

    const fixedColumnWidthPx = 270;

    const fixLeftColumn = projectStatusAlias === EProjectStatus.ContractSigned;

    return (
        <div className="project-state-content">
            <div
                style={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    height: isDesktop ? "20px" : "15px",
                    marginTop: isMobile && "8px",
                }}
            >
                <img
                    className="project-state-content-separator"
                    src={contentSeparator}
                    alt="Project State Content Separator"
                />
            </div>
            {projectStatusAlias !== "ProjectComplete" && (
                <div className="container project-state-content-container">
                    <div
                        className="row"
                        style={{
                            left: 0,
                        }}
                    >
                        <div
                            id="divContentLeft"
                            className="col-sm-6"
                            style={{
                                minWidth: !fixLeftColumn ? "326px" : undefined,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                width:
                                    (isDesktop &&
                                        (fixLeftColumn
                                            ? `${fixedColumnWidthPx}px`
                                            : `calc(100% - ${
                                                  fixedColumnWidthPx + 10
                                              }px`)) ||
                                    "100%",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        widows: "100%",
                                    }}
                                >
                                    <ProjectStateStamp
                                        projectStatus={projectStatus}
                                        estimatedStartDate={estimatedStartDate}
                                        contractSignedDate={contractSignedDate}
                                    />
                                </div>
                                {contractUploaded && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: isDesktop
                                                ? "flex-start"
                                                : "center",
                                        }}
                                    >
                                        <ViewContract
                                            status={status}
                                            documentUrl={documentUrl}
                                        />
                                        {/*  */}
                                    </div>
                                )}
                            </div>
                        </div>
                        {isBrowser && (
                            <div
                                id="divContentRight"
                                className="col-sm-6"
                                style={{
                                    minWidth: fixLeftColumn
                                        ? "350px"
                                        : undefined,
                                    width: fixLeftColumn
                                        ? `calc(100% - ${
                                              fixedColumnWidthPx + 10
                                          }px`
                                        : `${fixedColumnWidthPx}px`,
                                }}
                            >
                                <div>
                                    <ProjectStateMessage
                                        projectStatus={projectStatus}
                                        estimatedStartDate={estimatedStartDate}
                                        contractUploaded={contractUploaded}
                                        workflow={workflow}
                                    />
                                </div>
                            </div>
                        )}
                        {isMobile && (
                            <div style={{ marginTop: "10px" }}>
                                <ProjectStateMessage
                                    projectStatus={projectStatus}
                                    estimatedStartDate={estimatedStartDate}
                                    contractUploaded={contractUploaded}
                                    workflow={workflow}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

ContractSignedContent.propTypes = propTypes;
ContractSignedContent.defaultProps = defaultProps;
