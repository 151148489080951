import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

function useEventHandler() {
    const appInsights = useAppInsightsContext();

    /**
     * @param eventType {String}
     * @param eventDetails {Object}
     */
    const handleEvent = (eventType, eventDetails) => {
        appInsights.trackEvent({
            name: eventType,
            properties: eventDetails,
        });
    };

    return {
        handleEvent: handleEvent,
    };
}

export default useEventHandler;
