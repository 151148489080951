import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useCurrentProject } from "../../../../../../Redux/useGlobalState";
import Button from "../../../../../Button/Button";
import "./ServicePlan.css";
import {
    formatNotFound,
    formatPhoneNumber,
} from "../../../../../../utils/format";

export default function ServicePlan(props) {
    const formatApproximateDate = (date) => {
        if (!date) return formatNotFound(date);

        if (typeof date === "string") date = new Date(date);

        return date
            ? `${date.getMonth() + 1}/${date.getDate()}/${String(
                  date.getFullYear()
              )}`
            : null;
    };

    /**
     * @param {Date} date
     * @returns {string}
     */
    const formatAppointmentDate = (date) => {
        if (typeof date === "string") date = new Date(date);
        date.setHours(date.getHours());

        const m = date.getMonth() + 1;
        const d = date.getDate();
        const yyyy = date.getFullYear();
        let H = date.getHours();
        const M = date.getMinutes();
        const amPm = H < 12 ? "AM" : "PM";

        H = H > 12 ? H - 12 : H;
        if (H === 0) H = 12;
        const MM = M < 10 ? `0${M}` : M;

        const strMinutes = M ? `:${MM}` : "";

        return date ? `${m}/${d}/${yyyy} at ${H}${strMinutes} ${amPm}` : null;
    };

    const currentProject = useCurrentProject();
    const { hasServicePlan, servicePlan } = currentProject;
    const servicePlanApproximateDate =
        hasServicePlan && servicePlan.approximateDate;
    const servicePlanAppointmentDate =
        hasServicePlan && servicePlan.appointmentDate;
    const phoneNumber = servicePlan?.phoneNumber;

    const noDates = !(servicePlanApproximateDate || servicePlanAppointmentDate);

    return (
        <div className="service-plan" style={{ width: "100%" }}>
            {/* content header */}
            <div className="service-plan-title gotham-bold-black-20px">
                {!hasServicePlan &&
                    `${"It looks like you haven't purchased a service plan."}`}
                {hasServicePlan &&
                    (noDates
                        ? `${"Thank you for purchasing a service plan to protect your home!"}`
                        : `${"Congratulations on protecting your home with our service plan!"}`)}
            </div>

            {/* content body */}
            <div className="service-plan-body gotham-book-normal-black-16px">
                {hasServicePlan && noDates
                    ? `${"Please check back here for more details as your project progresses. If you have any questions about your plan, please call us!"}`
                    : `${"Our service program keeps your solutions operating properly and protects your home."}`}

                {(!hasServicePlan || !noDates) &&
                    !servicePlanAppointmentDate &&
                    servicePlanApproximateDate && (
                        <span className="gotham-bold-black-16px">
                            &nbsp;Our records indicate your next scheduled
                            maintenance should take place on or around{" "}
                            {formatApproximateDate(servicePlanApproximateDate)}.
                        </span>
                    )}

                {!hasServicePlan &&
                    `${" By getting regular service, you will ensure the long-lasting effects of the solutions we installed."}`}

                {hasServicePlan &&
                    !servicePlanAppointmentDate &&
                    !!servicePlan.servicePlanId &&
                    `${"  By getting regular maintenance, you are reducing the risk of expensive damage to your property. Your support team is also standing by to answer any questions you may have about your plan!"}`}

                {servicePlanAppointmentDate && (
                    <p className="service-date">
                        Your next scheduled service date is{" "}
                        <span className="gotham-bold-black-16px ">
                            {`${formatAppointmentDate(
                                servicePlanAppointmentDate
                            )}.`}
                        </span>{" "}
                        Your service admin is available to answer any questions
                        or if you need to reschedule.
                    </p>
                )}
            </div>
            {!hasServicePlan && (
                <div style={{ marginTop: "15px" }}>
                    <Button
                        icon={
                            <div className="fontawesome6pro-solid-normal-cloud-burst-20px">
                                
                            </div>
                        }
                        text={
                            <>
                                <BrowserView>
                                    {formatPhoneNumber(phoneNumber)}
                                </BrowserView>
                                <MobileView>
                                    <a
                                        href={`tel:+${phoneNumber}`}
                                        style={{
                                            color: "#172F53",
                                            textDecoration: "none",
                                        }}
                                    >
                                        {formatPhoneNumber(phoneNumber)}
                                    </a>
                                </MobileView>
                            </>
                        }
                    />
                </div>
            )}
        </div>
    );
}
