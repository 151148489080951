import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import DailyWorkContent from "./DailyWorkContent";
import { TDailyWorkGroup } from "../../../../../../../Redux/useGlobalState";
import "./DailyWorkPopup.css";
import { responsiveClassName } from "../../../../../../../utils/responsive";
import DivInteractive from "../../../../DivInteractive";

const propTypes = {
    popupOpen: PropTypes.bool,
    closePopup: PropTypes.func,
    dailyWorkGroup: PropTypes.objectOf(TDailyWorkGroup),
};

const defaultProps = {
    popupOpen: false,
    closePopup: null,
    dailyWorkGroup: null,
};

/**
 * @param {{popupOpen: boolean, closePopup: Function, dailyWorkGroup: TDailyWorkGroup}} props
 */
export default function DailyWorkPopup({
    popupOpen,
    closePopup,
    dailyWorkGroup,
}) {
    const summaryTasks = dailyWorkGroup.dailyWorkItems || [];

    return (
        <Modal
            show={popupOpen}
            onHide={closePopup}
            dialogClassName={responsiveClassName("cp-modal-dialog")}
            contentClassName="cp-modal-content"
            centered
        >
            <Modal.Body className={responsiveClassName("modal-body")}>
                <div className="modal-title" id="divDailyWorkTitle">
                    <div className="gotham-bold-cloud-burst-24px modal-title-text">
                        {dailyWorkGroup.title?.long}
                    </div>
                    <DivInteractive
                        className="fontawesome6pro-solid-normal-abbey-24px modal-title-close"
                        onClick={closePopup}
                        onKeyUp={() => false}
                    >
                        
                    </DivInteractive>
                </div>
                <div
                    id="divDailyWorkContent"
                    className={responsiveClassName("modal-body-content")}
                >
                    {summaryTasks.map((dailyWorkItem, index) => (
                        <DailyWorkContent
                            key={`dailyWorkItem_${index.toString()}`}
                            dailyWorkGroup={dailyWorkGroup}
                            workItemIndex={index}
                            isPopup
                        />
                    ))}
                </div>
                <div className="daily-work-modal-footer">
                    <Button
                        onClick={closePopup}
                        className="gotham-book-normal-cloud-burst-18px daily-work-modal-button"
                    >
                        Close
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

DailyWorkPopup.propTypes = propTypes;
DailyWorkPopup.defaultProps = defaultProps;
