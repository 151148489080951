import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import DivInteractive from "../MainContainer/Content/DivInteractive";
import "./Button.css";

const propTypes = {
    linkTo: PropTypes.string,
    icon: PropTypes.instanceOf(Object),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    style: PropTypes.instanceOf(Object),
    onClick: PropTypes.instanceOf(Function),
    className: PropTypes.string,
};

const defaultProps = {
    linkTo: null,
    icon: null,
    text: null,
    style: null,
    onClick: null,
    className: null,
};

export default function Button(props) {
    const { linkTo, icon, text, style, onClick, className } = props;

    const button = (
        <DivInteractive
            className={`main-button ${className || ""}`}
            style={{
                padding: "0 30px",
                cursor: onClick || linkTo ? "pointer" : "default",
                ...style,
            }}
            onClick={onClick}
        >
            {icon && <div style={{ marginRight: "10px" }}>{icon}</div>}
            <div
                style={{
                    whiteSpace: "nowrap",
                }}
                className="gotham-book-normal-cloud-burst-16px"
            >
                {text}
            </div>
        </DivInteractive>
    );

    return (
        (linkTo && (
            <Link to={linkTo} target="_blank">
                {button}
            </Link>
        )) || <div>{button}</div>
    );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
