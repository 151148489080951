import React from "react";
import { useDispatch } from "react-redux";
import { isDesktop, isMobile } from "react-device-detect";
import { useUserSession } from "../../../../Redux/useGlobalState";
import MenuItem from "./MenuItem";
import "./Menu.css";
import { ActionType } from "../../../../Redux/actions";
import { EMenuSelection } from "../../../../globals/globals";

export default function Menu() {
    const menuSelection = useUserSession()?.menuSelection;

    const dispatch = useDispatch();
    const selectMenuItem = (alias) => {
        dispatch({ type: ActionType.SET_MENU_SELECTION, menuSelection: alias });
    };

    return (
        <div
            className={`menu ${isDesktop && "menu-desktop"} ${
                isMobile && "menu-mobile"
            }`}
        >
            <MenuItem
                selection={menuSelection}
                label="Your Project"
                alias={EMenuSelection.YourProject}
                icon=""
                onClick={selectMenuItem}
            />
            <MenuItem
                selection={menuSelection}
                label="Special Offers"
                mobileLabel="Offers"
                alias={EMenuSelection.Offers}
                icon=""
                onClick={selectMenuItem}
            />
            {isMobile && (
                <MenuItem
                    selection={menuSelection}
                    label="Account Details"
                    mobileLabel="Account"
                    alias={EMenuSelection.AccountDetails}
                    icon=""
                    onClick={selectMenuItem}
                />
            )}
        </div>
    );
}
