import React from "react";
import PropTypes from "prop-types";
import {
    TNotification,
    TProjectStatus,
    TWorkflowItem,
    useCurrentAddress,
    useCurrentProject,
} from "../../../../../../Redux/useGlobalState";
import { EProjectStatus } from "../../../../../../globals/globals";
import Workflow from "./Workflow/Workflow";
import "./ProjectStateMessage.css";
import DailyWork from "./DailyWork/DailyWork";
import Notification from "./Notification/Notification";
import { ENotificationType } from "./Notification/eNotificationType";
import CpLink from "../../../../../../Controls/CpLink";
import Control from "../../../../../../Controls/Control";

const propTypes = {
    projectStatus: PropTypes.instanceOf(Object),
    estimatedStartDate: PropTypes.instanceOf(Date),
    contractUploaded: PropTypes.bool,
    workflow: PropTypes.arrayOf(Object),
};

const defaultProps = {
    projectStatus: null,
    estimatedStartDate: null,
    contractUploaded: false,
    workflow: null,
};

export default function ProjectStateMessage(props) {
    /**
     * @type {TProjectStatus}
     */
    const status = props.projectStatus;
    const { estimatedStartDate } = props;
    const { contractUploaded } = props;
    /**
     * @type TWorkflowItem[]
     */
    let workflow = null;
    workflow = props.workflow;

    const currentAddress = useCurrentAddress();
    const currentProject = useCurrentProject();

    const projectIsCancelled = !!currentProject?.isCancelled;

    const projectStatusAlias = status?.alias;

    const formatDate = (date) => {
        if (typeof date === "string") date = new Date(date);

        return date
            ? `${date.getMonth() + 1}/${date.getDate()}/${String(
                  date.getFullYear()
              ).slice(-2)}`
            : null;
    };

    const isProjectPreparation =
        projectStatusAlias === EProjectStatus.ProjectPreparation;
    const isProjectInProgress =
        projectStatusAlias === EProjectStatus.ProjectInProgress;

    /**
     * @param {TNotification} notification
     */
    const calcOtwLink = (notification) => (
        <CpLink to={notification.trackerLink}>
            Click here to track their arrival.
        </CpLink>
    );

    return (
        ![
            EProjectStatus.ProjectComplete,
            EProjectStatus.HomeProtection,
        ].includes(projectStatusAlias) && (
            <div className="project-state-message">
                {isProjectPreparation && (
                    <Notification
                        notificationTypeAlias={
                            ENotificationType.ProjectStartDateChanged
                        }
                        message="Your start date has changed."
                        className="notification-project-state-message"
                    />
                )}
                {isProjectInProgress && (
                    <Notification
                        notificationTypeAlias={
                            ENotificationType.ForemanOnTheWay
                        }
                        messagePrefix="Your foreman is en route!"
                        messagePrefixClassName="gotham-bold-black-16px"
                        message={calcOtwLink}
                        messageClassName="gotham-book-normal-black-16px"
                        className="notification-project-state-message notification-on-the-way"
                        dismissIcon={false}
                        iconText=""
                        iconTextClassName="fontawesome6pro-solid-normal-black-24px"
                        iconSize="54px"
                        iconFilled
                    />
                )}
                {isProjectInProgress && (
                    <div>
                        <DailyWork workflow={workflow} />
                    </div>
                )}
                {!isProjectInProgress && (
                    <p className="your-project-is-ready-to-schedule gotham-bold-black-20px">
                        {projectStatusAlias === EProjectStatus.ContractSigned &&
                            ((projectIsCancelled &&
                                "Your project has been canceled.") ||
                                (!estimatedStartDate &&
                                    "Your project is ready to schedule!") ||
                                (estimatedStartDate &&
                                    "Your project is scheduled."))}
                        {projectStatusAlias === "ProjectPreparation" &&
                            "Here's what's next."}
                    </p>
                )}
                {(projectStatusAlias === "ProjectComplete" ||
                    projectStatusAlias === "HomeProtection") &&
                    "promo shows here"}
                <div className="were-hard-at-work-s gotham-book-normal-black-16px">
                    <div className="cp-text">
                        {projectStatusAlias === EProjectStatus.ContractSigned &&
                            ((projectIsCancelled && (
                                <>
                                    If you would like to speak with us about
                                    signing a new contract, call us at{" "}
                                    <Control.Phone.Contact />.
                                </>
                            )) ||
                                (!estimatedStartDate &&
                                    `We're hard at work scheduling your project!
                        You should expect a call from your local production admin soon to discuss scheduling & next steps.`) ||
                                (estimatedStartDate &&
                                    `Your current start date is ${formatDate(
                                        estimatedStartDate
                                    )}. At that time, you'll meet your foreman & the crew helping repair your home.
                            If you need to discuss scheduling or other questions about your project,
                             your production admin is standing by.`))}
                        {projectStatusAlias === EProjectStatus.ContractSigned &&
                            !contractUploaded &&
                            " You will also be able to view your signed contract in the portal shortly."}

                        {projectStatusAlias === "ProjectPreparation" &&
                            `When you work with ${
                                currentAddress.brand?.name || "Groundworks"
                            }, you can trust that our team is expertly planning your project. Here is what is happening before the work starts.`}
                    </div>
                    {projectStatusAlias === "ProjectPreparation" && (
                        <div className="projectstate-workflow-container">
                            <Workflow workflow={workflow} />
                        </div>
                    )}
                </div>
            </div>
        )
    );
}

ProjectStateMessage.propTypes = propTypes;
ProjectStateMessage.defaultProps = defaultProps;
