import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const LogoutLink = () => {
    const { logout } = useAuth0();

    const logOut = () => {
        logout({
            logoutParams: {
                returnTo:
                    process.env.REACT_APP_LOGOUT_URL ||
                    `${window.location.origin}/home`,
            },
        });
    };

    return <Link onClick={() => logOut()}>click here</Link>;
};

export default LogoutLink;
