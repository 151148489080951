import React from "react";
import CpLink from "./CpLink";
import pixel from "../static/img/pixel.png";

const propTypes = {};

const defaultProps = {};

export default function CustomerSupport() {
    return (
        <CpLink to="mailto:customersupport@groundworks.com">
            {/* word break after "@" for wrapping */}
            customersupport@{/* */}
            <img alt="" src={pixel} width="0px" />
            {/* */}
            groundworks.com
        </CpLink>
    );
}

CustomerSupport.propTypes = propTypes;
CustomerSupport.defaultProps = defaultProps;
