import { isDesktop } from "react-device-detect";

const formatNotFound = (str) => {
    if (!str) return "(not found)";
    return str;
};

const formatPhoneNumber = (phoneNumber, missingIndicator) => {
    if (!phoneNumber) return missingIndicator || formatNotFound(phoneNumber);
    if (typeof phoneNumber === "number") phoneNumber = phoneNumber.toString();

    let newPhoneNumber = "";
    const strLen = phoneNumber.length;
    for (let strIdx = 0; strIdx < strLen; strIdx++) {
        if ([4, 7, 10].includes(strIdx)) newPhoneNumber = `-${newPhoneNumber}`;
        newPhoneNumber = phoneNumber[strLen - strIdx - 1] + newPhoneNumber;
    }
    return newPhoneNumber;
};

const formatDate = (date, missingIndicator, options = {}) => {
    if (typeof date === "string") date = new Date(date);

    if (!date) return missingIndicator;

    return date
        ? `${date.getMonth() + 1}/${date.getDate()}/${String(
              date
                  .getFullYear()
                  .toString()
                  .substr(
                      (options.yearDigits === 2 && 2) || (!isDesktop && 2) || 0,
                      options.yearDigits || (isDesktop && 4) || 2
                  )
          )}`
        : null;
};

export { formatNotFound, formatPhoneNumber, formatDate };
