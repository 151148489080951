import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PropTypes from "prop-types";
import { useContact, useUser } from "../Redux/useGlobalState";
import { formatPhoneNumber } from "../utils/format";
import "./ContactPhone.css";

const propTypes = {
    textClass: PropTypes.string,
};

const defaultProps = {
    textClass: "",
};

export default function ContactPhone({ textClass }) {
    const user = useUser();
    const contact = useContact();

    const phoneNumber = contact.phone;
    const formattedPhoneNumber = formatPhoneNumber(
        phoneNumber,
        user.userId === 0 && "xxx-xxx-xxxx"
    );

    return (
        <div className={`contact-phone ${textClass}`}>
            <BrowserView className="contact-phone">
                {formattedPhoneNumber}
            </BrowserView>
            <MobileView className="contact-phone">
                <a
                    href={(user.userId !== 0 && `tel:+${phoneNumber}`) || null}
                    style={{
                        color: "var(--allports)",
                    }}
                >
                    {formattedPhoneNumber}
                </a>
            </MobileView>
        </div>
    );
}

ContactPhone.propTypes = propTypes;
ContactPhone.defaultProps = defaultProps;
