import React from "react";
import { isDesktop } from "react-device-detect";
import UserOptions from "./UserOptions/UserOptions";
import "./Navigation.css";
import Logo from "../../../Logo/Logo";
import Menu from "./Menu/Menu";

export default function Navigation() {
    return (
        <>
            <div className="navigation-top">
                {isDesktop && <Logo />}
                {isDesktop && <Menu />}
            </div>
            <div>
                <UserOptions />
            </div>
        </>
    );
}
