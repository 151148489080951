import React from "react";
import "./Foreman.css";
import { isDesktop } from "react-device-detect";
import {
    TProject,
    useCurrentProject,
} from "../../../../../../Redux/useGlobalState";
import EmployeePhoto from "../../../../EmployeePhoto/EmployeePhoto";

export default function Foreman({ label }) {
    const currentProject = useCurrentProject();
    /**
     * @type {TProject}
     */
    const { foreman } = currentProject;

    return (
        <div>
            {isDesktop && (
                <div className="foreman-label gotham-book-normal-abbey-14px">
                    Your Foreman:
                </div>
            )}
            {(!foreman.id && (
                <div
                    className="gotham-book-normal-abbey-14px"
                    style={{ fontStyle: "italic" }}
                >
                    Awaiting Foreman Assignment
                </div>
            )) || (
                <>
                    {label && (
                        <div className="foreman-name gotham-bold-black-16px">
                            {foreman?.name}
                        </div>
                    )}
                    <div style={{ marginTop: isDesktop && "10px" }}>
                        <EmployeePhoto photoUrl={foreman?.photoUrl} anonymousImageEnabled />
                    </div>
                </>
            )}
        </div>
    );
}
