import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isDesktop, isMobile } from "react-device-detect";
import Property from "./Property/Property";
import Navigation from "./Navigation/Navigation";
import Content from "./Content/Content";
import "./MainContainer.css";
import Menu from "./Navigation/Menu/Menu";
import Logo from "../../Logo/Logo";
import SpocButton from "./Spoc/SpocButton";
import { useUserSession } from "../../Redux/useGlobalState";
import { EMenuSelection } from "../../globals/globals";

export default function MainContainer() {
    const [spocExpanded, setSpocExpanded] = useState(false);

    const colNavigation = useRef();
    const colContent = useRef();
    const colProperty = useRef();

    const updateColumnStyles = () => {
        const divNavigation = colNavigation.current;
        const divContent = colContent.current;
        const divProperty = colProperty.current;

        const offsetNavigation = divNavigation?.offsetTop;
        const offsetContent = divContent?.offsetTop;
        const offseProperty = divProperty?.offsetTop;

        if (divNavigation)
            divNavigation.style.maxWidth =
                offsetNavigation < offsetContent ? "10000px" : "400px";
        if (divProperty)
            divProperty.style.maxWidth =
                offsetContent < offseProperty ? "10000px" : "400px";
    };

    useEffect(() => {
        window.addEventListener("resize", updateColumnStyles);
        updateColumnStyles();
    }, []);

    const { menuSelection } = useUserSession();
    const showPropertyPanel = !(
        isMobile &&
        [EMenuSelection.Offers, EMenuSelection.AccountDetails].includes(
            menuSelection
        )
    );

    return (
        <>
            <Container
                id="divMainContainer"
                className="main-container"
                fluid
                onClick={
                    (isMobile && (() => setSpocExpanded(false))) || undefined
                }
            >
                <Row className="main-container-row">
                    {isDesktop && (
                        <Col
                            ref={colNavigation}
                            className={`navigation-column ${
                                isDesktop && "navigation-column-desktop"
                            }`}
                        >
                            <Navigation />
                        </Col>
                    )}
                    <Col
                        ref={colContent}
                        className={`content-column ${
                            isDesktop && "content-column-desktop"
                        }`}
                    >
                        {isMobile && <Logo />}
                        <Content />
                    </Col>
                    {showPropertyPanel && (
                        <Col
                            ref={colProperty}
                            className={`property-column ${
                                isDesktop && "property-column-desktop"
                            }`}
                        >
                            <Property />
                        </Col>
                    )}
                </Row>
                {isMobile && <Menu />}
            </Container>
            {isMobile && (
                <SpocButton
                    expanded={spocExpanded}
                    setExpanded={setSpocExpanded}
                />
            )}
        </>
    );
}
