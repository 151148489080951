import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function TProjectDocument() {
    this.id = null;
    this.documentTypeId = null;
    this.documentTypeAlias = null;
    this.documentTypeName = null;
    this.sectionId = null;
    this.url = null;
    this.description = null;
    this.dateAdded = null;
    this.createdDate = null;
    this.updatedDate = null;
}

function TProjectStatus() {
    this.id = null;
    this.alias = null;
    this.name = null;
}

function TWorkflowItem() {
    this.title = null;
    this.description = null;
    this.isCompleted = null;
    this.createdDate = null;
    this.updatedDate = null;
}

function TDailyWorkItem() {
    /**
     * @type {string}
     */
    this.title = null;
    /**
     * @type {string[]}
     */
    this.details = [];
}

function TDailyWorkGroup() {
    /**
     * @type {string}
     */
    this.alias = null;
    /**
     * @type {string}
     */
    this.title = null;
    /**
     * @type {string}
     */
    this.description = null;
    /**
     * @type {string}
     */
    this.note = null;
    /**
     * @type {TDailyWorkItem[]}
     */
    this.dailyWorkItems = null;
    /**
     * @type {string}
     */
    this.buttonLabel = null;
}

function TServicePlan() {
    this.servicePlanId = null;
    this.approximateDate = null;
    this.appointmentDate = null;
    this.phoneNumber = null;
}

function TEmployee() {
    this.id = null;
    this.name = null;
    this.photoUrl = null;
}

function TSurvey() {
    this.url = null;
    /**
     * @type {boolean}
     */
    this.isCompleted = null;
    this.rank = null;
}

function TWorkItem() {
    /**
     * @type {string}
     */
    this.title = null;
    /**
     * @type {string[]}
     */
    this.copy = null;
    /**
     * @type {string}
     */
    this.endDateTimeLocal = null;
}

function TProjectStatusHistoryDetails() {
    this.estimatedStartDate = null;
    /**
     * @type {TWorkflowItem[]}
     */
    this.workflow = null;
    /**
     * @type {TServicePlan}
     */
    this.servicePlan = null;
    /**
     * @type {TSurvey}
     */
    this.survey = null;
    this.estimatedCompletionDate = null;
    /**
     * @type {TSurvey}
     */
    this.completionDate = null;
    /**
     * @type {TWorkItem[]}
     */
    this.summary = null;
}

function TProjectStatusHistoryItem() {
    /**
     * @type {TProjectStatus}
     */
    this.projectStatus = null;
    /**
     * @type {TProjectStatusHistoryDetails}
     */
    this.details = null;
}

function TProject() {
    /**
     * @type {Number}
     */
    this.projectId = null;
    /**
     * @type {TProjectStatus}
     */
    this.currentProjectStatus = null;
    /**
     * @type {TProjectStatusHistoryItem[]}
     */
    this.projectStatusHistory = null;
    /**
     * @type {TProjectDocument[]}
     */
    this.documents = null;
    /**
     * @type {String}
     */
    this.currentDateTimeUtc = null;
    /**
     * @type {TEmployee}
     */
    this.foreman = null;
    /**
     * @type {boolean}
     */
    this.hasServicePlan = null;
    /**
     * @type {TServicePlan}
     */
    this.servicePlan = null;
    /**
     * @type {boolean}
     */
    this.isCancelled = null;
    /**
     * @type {String}
     */
    this.projectCancelledDate = null;
}

function TContact() {
    /**
     * @type {boolean}
     */
    this.isEscalated = null;
    /**
     * @type {String}
     */
    this.name = null;
    /**
     * @type {String}
     */
    this.title = null;
    /**
     * @type {String[]}
     */
    this.phone = null;
    /**
     * @type {String[]}
     */
    this.photoUrl = null;
}

function TNotificationType() {
    /**
     * @type {number}
     */
    this.id = null;
    /**
     * @type {string}
     */
    this.alias = null;
}

function TNotification() {
    /**
     * @type {boolean}
     */
    this.id = null;
    /**
     * @type {string}
     */
    this.notificationTypeAlias = null;
    /**
     * @type {string}
     */
    this.trackerLink = null;
}

function TBrand() {
    /**
     * @type {Number}
     */
    this.id = null;
    /**
     * @type {String}
     */
    this.name = null;
    /**
     * @type {String[]}
     */
    this.brandWebsiteUrl = null;
}

function TAddress() {
    /**
     * @type {Number}
     */
    this.addressId = null;
    this.address1 = null;
    this.address2 = null;
    this.city = null;
    this.state = null;
    this.zip = null;
    this.fullAddress = null;
    this.latitude = null;
    this.longitude = null;
    this.createdDate = null;
    this.updatedDate = null;
    /**
     * @type {TBrand}
     */
    this.brand = null;
    /**
     * @type {TProject[]}
     */
    this.projects = null;
}

function TUser() {
    this.userId = null;
    this.firstName = null;
    this.lastName = null;
    this.phoneNumber = null;
    this.emailAddress = null;
    this.isOverviewComplete = null;
    this.isOverviewComplete = null;
    this.isActive = null;
    this.createdDate = null;
    this.updatedDate = null;
    /**
     * @type {TAddress[]}
     */
    this.addresses = null;
}

function TOffer() {
    this.offerId = null;
    this.title = null;
    this.description = null;
    this.link = null;
    this.phoneNumber = null;
    this.terms = null;
    this.expirationDate = null;
    this.isPromoted = null;
    this.createdDate = null;
    this.updatedDate = null;
    this.isDeleted = null;
}

function TUserSession() {
    this.menuSelection = null;
    /**
     * @type {Date}
     */
    this.sessionStart = null;
    /**
     * @type {Function}
     */
    this.sessionDuration = null;
    /**
     * @type {boolean}
     */
    this.isAdminUser = false;
    /**
     * @type {boolean}
     */
    this.customerNotFound = false;
    /**
     * @type {number}
     */
    this.selectedProjectId = null;
}

function TGlobalState() {
    /**
     * @type {TProject}
     */
    this.currentProject = null;
    /**
     * @type {TAddress}
     */
    this.currentAddress = null;
    /**
     * @type {TUserSession}
     */
    this.userSession = null;
    /**
     * @type {TUser}
     */
    this.user = null;
    /**
     * @type {TOffer}
     */
    this.offers = null;
    /**
     * @type {TContact}
     */
    this.contact = null;
    /**
     * @type {TNotification[]}
     */
    this.notifications = null;
}

/**
 * @returns {TUser}
 */
export function useUser() {
    return useSelector(
        /**
         * @param {TGlobalState} state
         * @returns {TUser}
         */
        (state) => state.user
    );
}

/**
 * @returns {TOffer[]}
 */
export function useOffers() {
    return useSelector(
        /**
         * @param {TGlobalState} state
         * @returns {TOffer}
         */
        (state) => state.offers
    );
}

/**
 * @returns {TProject}
 */
export function useCurrentProject() {
    return useSelector(
        /**
         * @param {TGlobalState} state
         * @returns {TProject}
         */
        (state) => state.currentProject
    );
}

/**
 * @returns {TContact}
 */
export function useContact() {
    return useSelector(
        /**
         * @param {TGlobalState} state
         * @returns {TContact}
         */
        (state) => state.contact
    );
}

/**
 * @returns {TNotification[]}
 */
export function useNotifications() {
    return useSelector(
        /**
         * @param {TGlobalState} state
         * @returns {TNotification[]}
         */
        (state) => state.notifications
    );
}

/**
 * @returns {TAddress}
 */
export function useCurrentAddress() {
    return useSelector(
        /**
         * @param {TGlobalState} state
         * @returns {TAddress}
         */
        (state) => state.currentAddress
    );
}

/**
 * @returns {TBrand}
 */
export function useBrand() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let qsBrandId = queryParams.get("brandId");
    if (qsBrandId) qsBrandId = parseInt(qsBrandId);
    const { brand } = useCurrentAddress();

    if (qsBrandId !== null)
        return {
            id: qsBrandId,
            name: !qsBrandId ? "Groundworks" : brand.name,
            brandWebsiteUrl: !qsBrandId
                ? "https://groundworks.com"
                : brand.brandWebsiteUrl,
        };

    return brand;
}

/**
 * @returns {TUserSession}
 */
export function useUserSession() {
    return useSelector(
        /**
         * @param {TGlobalState} state
         * @returns {TUserSession}
         */
        (state) => state.userSession
    );
}

/**
 * @returns {number}
 */
export function sessionDuration() {
    return (new Date().getTime() - useUserSession().sessionStart) / 1000;
}

export const GlobalState = {};
export {
    TUser,
    TProject,
    TProjectStatus,
    TProjectDocument,
    TWorkflowItem,
    TDailyWorkGroup,
    TDailyWorkItem,
    TServicePlan,
    TEmployee,
    TOffer,
    TContact,
    TNotification,
};

GlobalState.setFeatureOverviewComplete = (complete = true) => {};

const dateOnly = (strDate) => {
    let dateOnly = new Date(strDate);
    dateOnly = new Date(
        dateOnly.getFullYear(),
        dateOnly.getMonth(),
        dateOnly.getDate()
    );
    return dateOnly;
};

/**
 * @param {TProject} project
 */
TProject.allComplete = (project) => {
    if (!project.projectId) return false;

    let allComplete = false;

    project.projectStatusHistory.forEach((statusHistoryItem) => {
        if (statusHistoryItem.projectStatus.alias === "ProjectComplete") {
            let { completionDate } = statusHistoryItem.details;

            if (completionDate) {
                completionDate = dateOnly(completionDate);
                completionDate.setDate(completionDate.getDate() + 7);
                const today = dateOnly(project.currentDateTimeUtc);
                if (completionDate.getTime() <= today.getTime())
                    allComplete = true;
            }
        }
    });

    return allComplete;
};

/**
 * @param {TProject} project
 */
TProject.getProjectDetails = (project) => {
    let contractSignedDate = null;
    let estimatedStartDate = null;
    let estimatedCompletionDate = null;
    let completionDate = null;
    let workflow = null;
    project?.projectStatusHistory?.forEach((projectStatus) => {
        contractSignedDate =
            contractSignedDate || projectStatus.details?.contractSignatureDate;
        estimatedStartDate =
            estimatedStartDate || projectStatus.details?.estimatedStartDate;
        estimatedCompletionDate =
            estimatedCompletionDate ||
            projectStatus.details?.estimatedCompletionDate;
        completionDate =
            completionDate || projectStatus.details?.completionDate;
        workflow = workflow || projectStatus.details?.workflow;
    });

    if (estimatedStartDate) estimatedStartDate = new Date(estimatedStartDate);
    if (completionDate) completionDate = new Date(completionDate);

    return {
        contractSignedDate,
        estimatedStartDate,
        estimatedCompletionDate,
        completionDate,
        workflow,
    };
};
