import React from "react";
import UserLoading from "./UserLoading";
import FeatureOverview from "./FeatureOverview";

export default function UserIsActive(props) {
    const { user, userIsActive, progress, projectNotAvailable } = props;

    return (
        <div>
            {/* <div>
                User{" "}
                <b>
                    {user?.firstName} {user?.lastName}
                </b>{" "}
                has logged in successfully.
            </div>
            <div>
                <b>{!userIsActive ? "Generic" : "Current"}</b> Project Page is
                displayed.
            </div> */}

            <UserLoading
                userIsActive={userIsActive}
                progress={progress}
                projectNotAvailable={projectNotAvailable}
            />
            <FeatureOverview user={user} visible={false} />
        </div>
    );
}
