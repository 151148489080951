import React from "react";
import { isDesktop } from "react-device-detect";
import ProjectDocuments from "./ProjectDocuments/ProjectDocuments";
import "./Property.css";
import Address from "./ProjectDocuments/Address/Address";
import { useUserSession } from "../../../Redux/useGlobalState";
import { EMenuSelection } from "../../../globals/globals";
import Spoc from "../Spoc/Spoc";

export default function Property() {
    const { menuSelection } = useUserSession();

    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "space-between",
            }}
            className="property-bottom"
        >
            {menuSelection === EMenuSelection.YourProject && (
                <ProjectDocuments />
            )}
            {isDesktop &&
                [EMenuSelection.Offers, EMenuSelection.AccountDetails].includes(
                    menuSelection
                ) && <Address />}
            {isDesktop && <Spoc />}
        </div>
    );
}
