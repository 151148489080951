import React, { useState } from "react";
import PropTypes from "prop-types";
import "./ViewOtherProjects.css";
import CpLink from "./CpLink";
import { TUser, useUser } from "../Redux/useGlobalState";
import MultiProjectPopup, {
    allProjectsCancelled,
} from "../Main/MainContainer/Content/Project/MultiProjectPopup";

const propTypes = { linkText: PropTypes.string };

const defaultProps = { linkText: "View Other Projects" };

/**
 * @param {TUser} user
 * @returns {boolean}
 */
const hasManyProjects = (user) => {
    let isGreater = false;
    let totalCount = 0;
    user.addresses?.forEach((address) => {
        totalCount += address.projects.length;
    });
    isGreater = totalCount > 1;
    return isGreater;
};

export default function ViewOtherProjects({ linkText }) {
    const user = useUser();

    const [popupOpen, setPopupOpen] = useState(allProjectsCancelled(user));

    const closePopup = () => setPopupOpen(false);
    const openPopup = () => {
        setPopupOpen(true);
        return false;
    };

    return (
        hasManyProjects(user) && (
            <>
                <CpLink
                    inlineText={false}
                    onClick={() => openPopup()}
                    className="gotham-book-normal-black-16px view-other-projects-link"
                >
                    {linkText}
                </CpLink>
                <MultiProjectPopup
                    popupOpen={popupOpen}
                    closePopup={closePopup}
                />
            </>
        )
    );
}

ViewOtherProjects.propTypes = propTypes;
ViewOtherProjects.defaultProps = defaultProps;

export { hasManyProjects };
