import React from "react";
import PropTypes from "prop-types";
import iconPlay from "../../../../../../../static/img/path-60-11@1x.png";
import { TDailyWorkGroup } from "../../../../../../../Redux/useGlobalState";
import "./DailyWorkContent.css";
import { EDailyWorkGroup } from "./enums";
import ProjectStateCompleted from "../../ProjectStateCompleted/ProjectStateCompleted";
import { formatDate } from "../../../../../../../utils/format";
import { dateOnly, today } from "../../../../../../../utils/dateTime";

const propTypes = {
    dailyWorkGroup: PropTypes.instanceOf(Object),
    isPopup: PropTypes.bool,
    workItemIndex: PropTypes.number,
};

const defaultProps = {
    dailyWorkGroup: {},
    isPopup: false,
    workItemIndex: null,
};

/**
 * @param {{dailyWorkGroup: TDailyWorkGroup, isPopup: boolean, workItemIndex: number}} props
 */
export default function DailyWorkContent({
    dailyWorkGroup,
    isPopup,
    workItemIndex,
}) {
    let heading = null,
        description = null,
        note = null,
        details = null,
        endDateTimeLocal = null;
    if (!isPopup) {
        heading = dailyWorkGroup.title.short;
        description = dailyWorkGroup.description;
        note = dailyWorkGroup.note;
        details = dailyWorkGroup.dailyWorkItems;
    } else {
        const dailyWorkItem = dailyWorkGroup.dailyWorkItems[workItemIndex];
        heading = dailyWorkItem.title;
        details = dailyWorkItem.details;
        endDateTimeLocal = dailyWorkItem.endDateTimeLocal;
    }

    const hasSummaryTasks = !!details?.length;
    const dailyWorkGroupAlias = dailyWorkGroup.alias;

    const completed =
        !!endDateTimeLocal && dateOnly(endDateTimeLocal) < today();
    const formattedDate = formatDate(endDateTimeLocal, "", {
        yearDigits: 2,
    });

    return (
        <div
            className={`daily-work-content ${
                isPopup && "daily-work-content-popup"
            }`}
        >
            <div
                className={`gotham-bold-black-${
                    !isPopup ? 20 : 18
                }px daily-work-content-heading ${
                    isPopup && "daily-work-content-heading-popup"
                }`}
            >
                {isPopup &&
                    dailyWorkGroupAlias === EDailyWorkGroup.WhatWeWillBeDoing &&
                    completed && (
                        <ProjectStateCompleted
                            isComplete
                            isGrayed={false}
                            className="workflow-checkbox"
                            isWorkflow
                        />
                    )}
                {heading}
            </div>
            {(!hasSummaryTasks || description) && (
                <div className="gotham-book-normal-black-18px daily-work-content-description">
                    {(!hasSummaryTasks &&
                        "Please refer to your foreman for details.") ||
                        description}
                </div>
            )}
            {note && (
                <div className="gotham-book-normal-black-16px daily-work-content-note">
                    <div className="gotham-bold-black-16px daily-work-content-note-prefix">
                        Please Note:
                    </div>{" "}
                    {note}
                </div>
            )}
            <div
                className={`gotham-book-normal-black-16px daily-work-content-details ${
                    isPopup && "daily-work-content-details-popup"
                }`}
            >
                {details.map((detail, index) => (
                    <React.Fragment key={`detail_${index.toString()}`}>
                        <div className="daily-work-content-detail">
                            {(!isPopup ||
                                dailyWorkGroupAlias ===
                                    EDailyWorkGroup.WhatToExpect) && (
                                <img
                                    className="icon-play daily-work-content-bullet"
                                    src={iconPlay}
                                    alt="icon-play"
                                />
                            )}
                            {!isPopup ? detail.title : detail}
                        </div>
                        {isPopup &&
                            dailyWorkGroupAlias ===
                                EDailyWorkGroup.WhatWeWillBeDoing && (
                                <div className="gotham-italic-abbey-14px daily-work-content-date">
                                    {(completed &&
                                        `Completed on ${formattedDate}`) ||
                                        (!completed &&
                                            !!endDateTimeLocal &&
                                            `Est. Completion Date: ${formattedDate}`) || (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "5px",
                                                }}
                                            >
                                                <ProjectStateCompleted
                                                    isComplete={false}
                                                    isGrayed={false}
                                                    className="dailyworktask-checkbox"
                                                    isDailyWorkTask
                                                />
                                                <div>
                                                    Task is not yet scheduled
                                                </div>
                                            </div>
                                        )}
                                </div>
                            )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

DailyWorkContent.propTypes = propTypes;
DailyWorkContent.defaultProps = defaultProps;
