import React from "react";
import "./Note.css";
import PropTypes from "prop-types";

const propTypes = {
    notePrefix: PropTypes.string,
    prefixClassName: PropTypes.string,
    noteText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Object),
    ]),
    textClassName: PropTypes.string,
};

const defaultProps = {
    notePrefix: null,
    prefixClassName: null,
    noteText: "(Note text here).",
    textClassName: null,
};

export default function Note({
    notePrefix,
    prefixClassName,
    noteText,
    textClassName,
}) {
    return (
        <div className="note">
            <div>
                <span
                    className={
                        prefixClassName || "gotham-bold-black-italic-16px"
                    }
                >
                    {notePrefix || "Please note:"}
                </span>{" "}
                <span
                    className={textClassName || "gotham-book-italic-black-16px"}
                >
                    {noteText}
                </span>
            </div>
        </div>
    );
}

Note.propTypes = propTypes;
Note.defaultProps = defaultProps;
