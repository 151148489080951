import React from "react";
import PropTypes from "prop-types";
import "./MenuItem.css";
import { isDesktop, isMobile } from "react-device-detect";
import { useBrand } from "../../../../Redux/useGlobalState";

const propTypes = {
    selection: PropTypes.string,
    label: PropTypes.string,
    mobileLabel: PropTypes.string,
    alias: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
};

const defaultProps = {
    selection: null,
    label: null,
    mobileLabel: null,
    alias: null,
    icon: null,
    onClick: null,
};

const importBrandStylesheet = async (brand) => {
    try {
        await import(`../../../../brands/styles/brand_${brand.id}.css`);
    } catch (e) {
        await import(`../../../../brands/styles/brand_0.css`);
    }
};

export default function MenuItem(props) {
    const { selection, label, mobileLabel, alias, icon, onClick } = props;

    const brand = useBrand();
    importBrandStylesheet(brand);

    const selected = selection === alias;
    const iconFont = `fontawesome6pro-solid-normal-${
        selected ? "glacier" : "abbey"
    }-${isDesktop ? "32" : "28"}px`;
    const labelFontSize = `${isDesktop ? "24" : "14"}`;
    return (
        <div
            className={`menu-item ${isMobile && "menu-item-mobile"}`}
            onClick={() => onClick(alias)}
            onKeyUp={() => false}
        >
            <div
                className={`${iconFont} menu-item-icon ${
                    isMobile && "menu-item-icon-mobile"
                }`}
                style={{
                    color: selected ? "var(--color-primary)" : "var(--abbey)",
                }}
            >
                {icon}
            </div>
            <div
                className={`menu-item-label ${
                    isMobile && "menu-item-label-mobile"
                } ${
                    selected
                        ? `gotham-bold-abbey-${labelFontSize}px`
                        : `gotham-book-normal-abbey-${labelFontSize}px`
                }`}
            >
                {(isMobile && mobileLabel) || label}
            </div>
        </div>
    );
}

MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;
