import React from "react";
import Logo from "../Logo/Logo";
import "./ErrorPage.css";
import Circle from "./Circle";
import Button from "../Main/Button/Button";
import { useLogout } from "../utils/hooks";
import CustomerSupport from "../Controls/CustomerSupport";
import Control from "../Controls/Control";

export default function ErrorPage() {
    const { logout } = useLogout();
    return (
        <div className="error-page">
            <Logo />
            <div className="error-page-modal-background">
                <div className="error-page-modal">
                    <div className="error-page-modal-heading gotham-bold-cloud-burst-54px">
                        <Circle />
                        <div>Please Stand By...</div>
                    </div>
                    <div className="error-page-modal-message gotham-bold-cloud-burst-33px">
                        Something went wrong.
                    </div>
                    <div className="error-page-modal-instructions gotham-book-normal-black-18px">
                        <div>Please sign out or email your support team at</div>
                        <div>
                            <Control.Link.CustomerSupport />.
                        </div>
                    </div>
                    <div className="error-page-buttons">
                        <Button
                            text="Sign Out"
                            onClick={logout}
                            className="main-button-sign-out"
                        />
                        <Button
                            text="Email Support Team"
                            linkTo="mailto:customersupport@groundworks.com"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
