import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./Redux/store";
import "./globals.css";
import "./styleguide.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

const { location } = document;
const isAdminUser = location.pathname.toLowerCase() === "/admin";
/* eslint-disable prefer-destructuring */
const env = process.env;
/* eslint-enable */

root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={env.REACT_APP_AUTH0_DOMAIN}
            clientId={
                env[`REACT_APP_AUTH0_CLIENTID${isAdminUser ? "_ADMIN" : ""}`]
            }
            authorizationParams={{
                redirect_uri: window.location.href,
                audience: env.REACT_APP_AUTH0_AUDIENCE,
            }}
        >
            <Provider store={store}>
                <App isAdminUser={isAdminUser} />
            </Provider>
        </Auth0Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
