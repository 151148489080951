import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ActionType } from "../Redux/actions";
import useAPIService from "../APIService/apiService";

export default function FeatureOverview(props) {
    const { user, visible } = props;
    const [overviewIndex, setOverviewIndex] = useState(0);

    const overviewItems = [
        "Start feature overview",
        "Your project details",
        "Your special offers page",
        "Your project documents",
        "Your point of contact",
        "Switch between your projects",
    ];

    const dispatch = useDispatch();
    const { apiPut } = useAPIService();
    const nextOverviewItem = async () => {
        const nextOverviewIndex = overviewIndex + 1;
        if (overviewItems.length <= nextOverviewIndex) {
            await apiPut(
                "Users",
                {
                    userId: user.userId,
                    isOverviewComplete: true,
                },
                null,
                { noResponseData: true }
            );

            dispatch({
                type: ActionType.SET_FEATURE_OVERVIEW_COMPLETE,
                complete: true,
            });
        }
        setOverviewIndex(nextOverviewIndex);
    };

    return (
        visible &&
        user.isOverviewComplete === false && (
            <div>
                <div
                    style={{
                        position: "absolute",
                        backgroundColor: "black",
                        opacity: "50%",
                        left: "0",
                        top: "0",
                        width: "100%",
                        height: "100%",
                    }}
                />
                <div
                    style={{
                        padding: "10px 20px 10px 20px",
                        position: "absolute",
                        backgroundColor: "white",
                        left: "calc(50% - 200px)",
                        top: "calc(50% - 100px)",
                        width: "400px",
                        height: "200px",
                        opacity: "100%",
                    }}
                >
                    <div>
                        <b>Feature Overview</b>
                    </div>
                    <div>
                        Overview: {overviewItems[overviewIndex]}{" "}
                        <a href="#" onClick={() => nextOverviewItem()}>
                            Next
                        </a>
                    </div>
                </div>
            </div>
        )
    );
}
