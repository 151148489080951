import React from "react";
import "./Icon.css";
import PropTypes from "prop-types";
import DivInteractive from "../../../DivInteractive";

const propTypes = {
    border: PropTypes.string,
    backgroundColor: PropTypes.string,
    text: PropTypes.string,
    textClassName: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    filled: PropTypes.bool,
};

const defaultProps = {
    border: "solid 3px var(--orange)",
    backgroundColor: "var(--white)",
    text: "!",
    textClassName: "gotham-bold-black-12px",
    onClick: null,
    size: "18px",
    filled: false,
};

export default function Icon({
    border,
    backgroundColor,
    text,
    textClassName,
    onClick,
    size,
    filled,
}) {
    return (
        <DivInteractive
            className={`icon ${filled && "icon-filled"}`}
            style={{
                backgroundColor: backgroundColor,
                border: border,
                cursor: onClick ? "pointer" : "default",
                height: !filled ? size : "100%",
                width: size,
                minWidth: size,
            }}
            onClick={onClick}
        >
            <div className={textClassName}>{text}</div>
        </DivInteractive>
    );
}

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;
