import React from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import contentSeparator from "../../../../../../static/img/line-8-1@1x.png";
import ProjectStateStamp from "../ProjectStateStamp";
import Foreman from "../Foreman/Foreman";
import {
    TProject,
    TProjectStatus,
    TWorkflowItem,
} from "../../../../../../Redux/useGlobalState";
import { EProjectStatus } from "../../../../../../globals/globals";
import Survey from "../Survey/Survey";
import ServicePlan from "../ServicePlan/ServicePlan";
import "./ProjectStateContent.css";

const propTypes = {
    projectStatus: PropTypes.instanceOf(Object),
    estimatedStartDate: PropTypes.instanceOf(Date),
    contractSignedDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    projectStateMessage: PropTypes.instanceOf(Object),
};

const defaultProps = {
    projectStatus: null,
    estimatedStartDate: null,
    contractSignedDate: null,
    projectStateMessage: null,
};

/**
 * @param {Object} props
 * @param {TProjectStatus} props.projectStatus
 * @param {Date} props.estimatedStartDate
 * @param {Date} props.contractSignedDate
 * @param {bool} props.contractUploaded
 * @param {TWorkflowItem[]} props.workflow
 * @param {string} props.documentUrl
 * @param {string} props.status
 */
export default function ProjectStateContent(props) {
    const {
        projectStatus,
        estimatedStartDate,
        contractSignedDate,
        projectStateMessage,
    } = props;

    const projectStatusAlias = projectStatus?.alias;

    return (
        <div className="project-state-content">
            <div
                style={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    height: isDesktop ? "20px" : "15px",
                    marginTop: isMobile && "8px",
                }}
            >
                <img
                    className="project-state-content-separator"
                    src={contentSeparator}
                    alt="Project State Content Separator"
                />
            </div>
            {projectStatusAlias === "ProjectComplete" && (
                <Row className="row-survey">
                    <Col className="col-survey">
                        <Survey />
                    </Col>
                    {/* <Col className="col-promo">
                        <Promo />
                    </Col> */}
                </Row>
            )}
            {projectStatusAlias !== "ProjectComplete" && (
                <div className="container project-state-content-container">
                    <div
                        className="row"
                        style={{
                            left: 0,
                            margin: "0 0 0 0",
                        }}
                    >
                        <div
                            id="divContentLeft"
                            style={{
                                minWidth: "326px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                padding: "0 0 0 0",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                }}
                            >
                                {projectStatusAlias === "ProjectComplete" && (
                                    <Survey />
                                )}
                                {projectStatusAlias === "HomeProtection" && (
                                    <ServicePlan />
                                )}
                                {(isDesktop ||
                                    projectStatusAlias !==
                                        EProjectStatus.ProjectInProgress) &&
                                    projectStatusAlias !== "ProjectComplete" &&
                                    projectStatusAlias !== "HomeProtection" && (
                                        <div
                                            style={{
                                                widows: "100%",
                                            }}
                                        >
                                            {" "}
                                            c
                                            <ProjectStateStamp
                                                projectStatus={projectStatus}
                                                estimatedStartDate={
                                                    estimatedStartDate
                                                }
                                                contractSignedDate={
                                                    contractSignedDate
                                                }
                                            />
                                        </div>
                                    )}
                                {isDesktop &&
                                    projectStatusAlias ===
                                        "ProjectInProgress" && (
                                        <Foreman label />
                                    )}
                            </div>
                        </div>
                        {isMobile && projectStateMessage}
                    </div>
                </div>
            )}
        </div>
    );
}

ProjectStateContent.propTypes = propTypes;
ProjectStateContent.defaultProps = defaultProps;
