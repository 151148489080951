import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { isDesktop, isMobile } from "react-device-detect";
import {
    TProjectStatus,
    useCurrentProject,
} from "../../../../../Redux/useGlobalState";
import { EProjectStatus } from "../../../../../globals/globals";
import { formatDate } from "../../../../../utils/format";

const propTypes = {
    projectStatus: PropTypes.instanceOf(Object),
    contractSignedDate: PropTypes.string,
    estimatedStartDate: PropTypes.instanceOf(Date),
    estimatedCompletionDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    projectCompletionDate: PropTypes.instanceOf(Date),
};

const defaultProps = {
    projectStatus: null,
    contractSignedDate: null,
    estimatedStartDate: null,
    estimatedCompletionDate: null,
    projectCompletionDate: null,
};

const stampStyle = (projectStatusAlias) => {
    return {
        padding: ["ProjectPreparation", "ProjectInProgress"].includes(
            projectStatusAlias
        )
            ? "10px 10px 10px 10px"
            : undefined,
        width:
            (isMobile && "100%") ||
            (projectStatusAlias === "ProjectPreparation" && "137px") ||
            (projectStatusAlias === "ProjectInProgress" && "137px") ||
            (projectStatusAlias === "ProjectComplete" && "500px") ||
            (projectStatusAlias === "HomeProtection" && "500px") ||
            undefined,
        height:
            (projectStatusAlias === "ProjectPreparation" && "85px") ||
            (projectStatusAlias === "ProjectInProgress" && "85px") ||
            undefined,
    };
};

export default function ProjectStateStamp(props) {
    /**
     * @type {TProjectStatus}
     */
    const status = props.projectStatus;
    const { contractSignedDate } = props;
    const { estimatedStartDate } = props;
    const { estimatedCompletionDate } = props;
    const { projectCompletionDate } = props;

    const projectStatusAlias = status?.alias;

    const setWidth = () => {
        if (
            projectStatusAlias !== "ProjectComplete" &&
            projectStatusAlias !== "HomeProtection"
        )
            return;
        const divContentLeft = document.getElementById("divContentLeft");
        const divStamp = document.getElementById("divStamp");
        divStamp.style.width = `${divContentLeft.offsetWidth - 25}px`;
    };

    window.addEventListener("resize", () => setWidth(), true);

    useEffect(() => setWidth(), []);

    let isProjectPreparation = false,
        isProjectInProgress = false;
    switch (projectStatusAlias) {
        case "ProjectPreparation":
            isProjectPreparation = true;
            break;
        case "ProjectInProgress":
            isProjectInProgress = true;
            break;
        default:
    }

    const currentProject = useCurrentProject();
    const isProjectComplete =
        currentProject.currentProjectStatus.alias ===
        EProjectStatus.ProjectComplete;

    const contractSignedMessage =
        projectStatusAlias === EProjectStatus.ContractSigned &&
        ((currentProject.isCancelled &&
            `This project was canceled on ${formatDate(
                currentProject.dateCancelled,
                "(date not found)",
                { yearDigits: 2 }
            )}`) ||
            `Thank you for signing your contract on ${formatDate(
                contractSignedDate
            )}`);

    return (
        (isMobile && (
            <div className="project-state-stamp-mobile">
                <div className="fontawesome6pro-light-cloud-burst-50px"></div>
                <div className="project-state-stamp-mobile-text gotham-book-normal-black-16px">
                    {contractSignedMessage}
                </div>
            </div>
        )) || (
            <div
                id="divStamp"
                className={`project-state-stamp ${
                    projectStatusAlias === "ProjectComplete" ||
                    projectStatusAlias === "HomeProtection"
                        ? "project-state-stamp-complete"
                        : ""
                }`}
                style={stampStyle(projectStatusAlias)}
            >
                {projectStatusAlias === EProjectStatus.ContractSigned && (
                    <div
                        className={`text-27 fontawesome6pro-light-cloud-burst-${
                            isDesktop ? "79" : "50"
                        }px`}
                    >
                        
                    </div>
                )}
                <div className="thank-you-for-signin gotham-book-normal-black-16px">
                    {contractSignedMessage}
                    {[
                        EProjectStatus.ProjectPreparation,
                        EProjectStatus.ProjectInProgress,
                    ].includes(projectStatusAlias) && (
                        <div>
                            <div
                                style={{
                                    color: "#505257",
                                    fontSize:
                                        (isProjectPreparation && "14px") ||
                                        (isProjectInProgress && "13px"),
                                }}
                            >
                                {isProjectPreparation && "Project Start Date:"}
                                {isProjectInProgress &&
                                    (!isProjectComplete ? (
                                        "Estimated Completion Date:"
                                    ) : (
                                        <div style={{ whiteSpace: "pre-line" }}>
                                            {"Completed\nDate:"}
                                        </div>
                                    ))}
                            </div>
                            <div
                                style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                                {isProjectPreparation &&
                                    formatDate(estimatedStartDate)}
                                {isProjectInProgress &&
                                    formatDate(
                                        !isProjectComplete
                                            ? estimatedCompletionDate
                                            : projectCompletionDate,
                                        "TO BE SCHEDULED"
                                    )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    );
}

ProjectStateStamp.propTypes = propTypes;
ProjectStateStamp.defaultProps = defaultProps;
