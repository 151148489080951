import React from "react";
import LogoutButton from "../../Authentication/LogoutButton";

export default function AccountNotReady() {
    return (
        <div>
            <div>
                <b>Your project information is not yet available,</b> please
                refresh the page in about 30 minutes.
            </div>
            <div>
                <LogoutButton />
            </div>
        </div>
    );
}
