import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./CpLink.css";

const propTypes = {
    to: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Object),
    ]),
    target: PropTypes.string,
    onClick: PropTypes.func,
    inlineText: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    to: null,
    children: null,
    target: "_blank",
    onClick: null,
    inlineText: true,
    className: "",
};

export default function CpLink({
    to,
    children,
    target,
    onClick,
    inlineText,
    className,
}) {
    return (
        <Link
            to={to}
            className={`cp-link gotham-book-normal-black-16px  ${
                inlineText && "cp-link-inline-text"
            } ${className}`}
            target={(!onClick && target) || undefined}
            onClick={onClick}
        >
            {children}
        </Link>
    );
}

CpLink.propTypes = propTypes;
CpLink.defaultProps = defaultProps;
