/**
 * @param {Date} date
 * @returns {Date}
 */
const dateOnly = (date) => {
    if (!date) return date;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

/**
 * @returns {Date}
 */
const today = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

/**
 * @returns {Date}
 */
const toDate = (date) => {
    if (typeof date === "string") return new Date(date);
    return date;
};

/**
 * @returns {boolean}
 */
const sameDate = (dateTime1, dateTime2) => {
    return toDate(dateTime1).getTime() === toDate(dateTime2).getTime();
};

export { today, sameDate, dateOnly };
