import { useEffect } from "react";

/* will be adding more functions */
/* eslint-disable-next-line import/prefer-default-export */
export function useEffectAsync(fnAsync, deps) {
    useEffect(
        () => {
            fnAsync();
        },
        deps !== undefined ? deps : []
    );
}
