import React from "react";
import PropTypes from "prop-types";
import "./ContentSeparator.css";
import contentSeparator from "../../../../static/img/line-8-1@1x.png";
import EContentSeparator from "./EContentSeparator";

const propTypes = {
    useDiv: PropTypes.bool,
    orientation: PropTypes.string,
    height: PropTypes.string,
    gap: PropTypes.string,
};

const defaultProps = {
    useDiv: false,
    orientation: EContentSeparator.Horizontal,
    height: null,
    gap: null,
};

export default function ContentSeparator({ useDiv, orientation, height, gap }) {
    return (
        <div>
            {(useDiv && (
                <div
                    className={`content-separator content-separator-${orientation.toLowerCase()}`}
                    style={{
                        height: height !== null && height,
                        marginLeft:
                            gap &&
                            orientation === EContentSeparator.Vertical &&
                            gap,
                        marginRight:
                            gap &&
                            orientation === EContentSeparator.Vertical &&
                            gap,
                    }}
                >
                    &nbsp;
                </div>
            )) || (
                <img
                    className="content-separator"
                    src={contentSeparator}
                    alt="Content Separator"
                />
            )}
        </div>
    );
}

ContentSeparator.propTypes = propTypes;
ContentSeparator.defaultProps = defaultProps;
