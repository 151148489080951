import React from "react";
import CustomerSupport from "./CustomerSupport";
import ContactPhone from "./ContactPhone";

const propTypes = {};

const defaultProps = {};

export default function Control() {
    return "Control";
}

Control.propTypes = propTypes;
Control.defaultProps = defaultProps;

Control.Link = {
    CustomerSupport: CustomerSupport,
};

Control.Phone = {
    Contact: ContactPhone,
};
