import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import ProjectStateLabel from "./ProjectStateLabel/ProjectStateLabel";
import ProjectStateDetails from "./ProjectStateDetails";
import DivInteractive from "../../DivInteractive";
import ProjectStateContext from "./ProjectStateContext";
import { useUserSession } from "../../../../../Redux/useGlobalState";
import { ActionType } from "../../../../../Redux/actions";

const propTypes = {
    isOpen: PropTypes.bool,
    project: PropTypes.instanceOf(Object),
    status: PropTypes.string,
    isHomeProtection: PropTypes.bool,
    hasContent: PropTypes.bool,
    isGrayed: PropTypes.bool,
    stateIndex: PropTypes.number,
};

const defaultProps = {
    isOpen: false,
    project: null,
    status: null,
    isHomeProtection: false,
    hasContent: false,
    isGrayed: false,
    stateIndex: null,
};

export default function ProjectStateHeader(props) {
    const {
        isOpen,
        project,
        status,
        isHomeProtection,
        hasContent,
        isGrayed,
        stateIndex,
    } = props;

    const { selectedProjectStatus } = useUserSession();
    const dispatch = useDispatch();

    const projectStateContext = useContext(ProjectStateContext);

    const toggleStatus = () => {
        dispatch({
            type: ActionType.SET_SELECTED_STATUS,
            selectedProjectStatus:
                status === selectedProjectStatus ? null : status,
        });

        if (projectStateContext.fitImage) projectStateContext.fitImage(true);
    };

    return (
        <DivInteractive
            className="project-state-header"
            id={`divProjectStateHeader_${stateIndex}`}
            onClick={toggleStatus}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <ProjectStateLabel
                    isOpen={isOpen}
                    project={project}
                    status={status}
                    isHomeProtection={isHomeProtection}
                    hasContent={hasContent}
                    isGrayed={isGrayed}
                    stateIndex={stateIndex}
                />
                {!isGrayed && hasContent && (
                    <ProjectStateDetails
                        isHomeProtection={isHomeProtection}
                        project={project}
                        isOpen={isOpen}
                        stateIndex={stateIndex}
                    />
                )}
            </div>
        </DivInteractive>
    );
}

ProjectStateHeader.propTypes = propTypes;
ProjectStateHeader.defaultProps = defaultProps;
