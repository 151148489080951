import { createReducer } from "@reduxjs/toolkit";
import { ActionType } from "../actions";

const initialState = [];

const notificationsReducer = createReducer(initialState, (builder) => {
    builder.addCase(ActionType.SET_NOTIFICATIONS, (state, action) => {
        const { notifications } = action;
        state.length = 0;
        notifications?.forEach((notification) => {
            state.push(notification);
        });
    });
});

export default notificationsReducer;
