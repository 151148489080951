import { createReducer } from "@reduxjs/toolkit";
import { ActionType } from "../actions";

const initialState = {};

const contactReducer = createReducer(initialState, (builder) => {
    builder.addCase(ActionType.SET_CONTACT, (state, action) => {
        const { contact } = action;
        for (const key in contact) state[key] = contact[key];
    });
});

export default contactReducer;
