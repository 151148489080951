import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
    Accordion,
    AccordionContext,
    Card,
    useAccordionButton,
} from "react-bootstrap";
import { BrowserView, MobileView, isDesktop } from "react-device-detect";
import { TOffer } from "../../../../../Redux/useGlobalState";
import Button from "../../../../Button/Button";
import "./Offer.css";

const propTypes = {
    eventKey: PropTypes.string,
    offer: PropTypes.instanceOf(Object),
};

const defaultProps = {
    eventKey: null,
    offer: null,
};

/**
 * @typedef {Object} Props
 * @property {TOffer} offer
 * @property {string} key
 */

/**
 * @param {Props} props
 */
export default function Offer(props) {
    const { eventKey, offer } = props;

    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey);

    const { link, phoneNumber, terms, expirationDate } = offer;
    const hasBody = link || phoneNumber || terms;
    const isCurrentEventKey = activeEventKey === eventKey;

    /**
     * @param {Date} date
     * @returns
     */
    const formatDate = (date) => {
        if (typeof date === "string") date = new Date(date);

        return date
            ? `${date.getMonth() + 1}/${date.getDate()}/${String(
                  date.getFullYear().toString().substring(2, 4)
              )}`
            : null;
    };

    return (
        <Card
            onClick={isCurrentEventKey ? null : decoratedOnClick}
            className={`offer-card
                ${isCurrentEventKey && "offer-card-selected"}`}
        >
            <Card.Header
                style={{
                    backgroundColor: "transparent",
                    border: "none",
                    paddingBottom: 0,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        className={`gotham-bold-${
                            isCurrentEventKey ? "allports" : "black"
                        }-${(isDesktop && "22") || "18"}px`}
                        style={{
                            marginBottom: "15px",
                        }}
                    >
                        <div
                            className="cp-text"
                            style={{
                                display: "inline-block",
                                paddingBottom: "2px",
                                textDecoration: "underline",
                                lineHeight: "26px",
                            }}
                        >
                            {offer.title}
                        </div>
                    </div>
                    <div
                        className="cp-text gotham-book-normal-black-16px"
                        style={{ lineHeight: "22px" }}
                    >
                        {offer.description}
                    </div>
                    {expirationDate && (
                        <div
                            className="gotham-italic-abbey-14px"
                            style={{
                                whiteSpace: "nowrap",
                                flexDirection: "column",
                                justifyContent: "flex-end",
                                display:
                                    isCurrentEventKey && hasBody
                                        ? "none"
                                        : "flex",
                                marginTop: "10px",
                                alignItems: "flex-end",
                            }}
                        >
                            Offer expires: {formatDate(expirationDate)}
                        </div>
                    )}
                </div>
            </Card.Header>
            {hasBody && (
                <Accordion.Collapse eventKey={eventKey}>
                    <Card.Body style={{ paddingTop: "25px" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: isDesktop ? "row" : "column",
                                    gap: "20px",
                                    flexWrap: "wrap",
                                }}
                            >
                                {link && (
                                    <Button text="Learn More" linkTo={link} />
                                )}
                                {phoneNumber && (
                                    <Button
                                        icon={
                                            <div className="fontawesome6pro-solid-normal-cloud-burst-20px">
                                                
                                            </div>
                                        }
                                        text={
                                            <>
                                                <BrowserView>
                                                    {phoneNumber}
                                                </BrowserView>
                                                <MobileView>
                                                    <a
                                                        href={`tel:+${phoneNumber}`}
                                                        style={{
                                                            color: "#172F53",
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        {phoneNumber}
                                                    </a>
                                                </MobileView>
                                            </>
                                        }
                                    />
                                )}
                            </div>
                            {terms && (
                                <div
                                    className="cp-text gotham-italic-abbey-14px"
                                    style={{
                                        marginTop: "25px",
                                        lineHeight: "20px",
                                    }}
                                >
                                    {terms}
                                </div>
                            )}
                            {expirationDate && (
                                <div
                                    className="gotham-italic-abbey-14px"
                                    style={{
                                        whiteSpace: "nowrap",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        marginTop: "10px",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    Offer expires: {formatDate(expirationDate)}
                                </div>
                            )}
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            )}
        </Card>
    );
}

Offer.propTypes = propTypes;
Offer.defaultProps = defaultProps;
