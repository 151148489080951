import React from "react";
import PropTypes from "prop-types";
import "./DateStamp.css";
import { formatDate } from "../../../../../../utils/format";

const propTypes = {
    label: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

const defaultProps = {
    label: null,
    date: null,
};

export default function DateStamp({ label, date }) {
    return (
        <div className="date-stamp">
            <div className="gotham-book-normal-abbey-14px date-stamp-date">
                {label || "Date"}:
            </div>
            <div className="gotham-bold-black-16px date-stamp-label">
                {typeof date !== "string" ? formatDate(date, "N/A") : date}
            </div>
        </div>
    );
}

DateStamp.propTypes = propTypes;
DateStamp.defaultProps = defaultProps;
