import React from "react";
import { isDesktop, isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import contentSeparator from "../../../../../../static/img/line-8-1@1x.png";
import {
    TProject,
    TProjectStatus,
    TWorkflowItem,
} from "../../../../../../Redux/useGlobalState";
import { EProjectStatus } from "../../../../../../globals/globals";
import "./ProjectStateContent.css";
import ProjectInProgressContainer from "./ProjectInProgressContainer";

const propTypes = {
    projectStatus: PropTypes.instanceOf(Object),
    projectStateMessage: PropTypes.instanceOf(Object),
    foremanRow: PropTypes.instanceOf(Object),
    projectStateStamp: PropTypes.instanceOf(Object),
};

const defaultProps = {
    projectStatus: null,
    projectStateMessage: null,
    foremanRow: null,
    projectStateStamp: null,
};

/**
 * @param {Object} props
 * @param {TProjectStatus} props.projectStatus
 * @param {Date} props.estimatedStartDate
 * @param {Date} props.contractSignedDate
 * @param {bool} props.contractUploaded
 * @param {TWorkflowItem[]} props.workflow
 * @param {string} props.documentUrl
 * @param {string} props.status
 */
export default function ProjectInProgressContent(props) {
    const {
        projectStatus,
        projectStateMessage,
        foremanRow,
        projectStateStamp,
    } = props;

    const projectStatusAlias = projectStatus?.alias;

    return (
        <div className="project-state-content">
            <div
                style={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    height: isDesktop ? "20px" : "15px",
                    marginTop: isMobile && "8px",
                }}
            >
                <img
                    className="project-state-content-separator"
                    src={contentSeparator}
                    alt="Project State Content Separator"
                />
            </div>
            {isMobile &&
                [EProjectStatus.ProjectInProgress].includes(
                    projectStatusAlias
                ) &&
                foremanRow}
            <ProjectInProgressContainer
                projectStatus={projectStatus}
                projectStateStamp={projectStateStamp}
                projectStateMessage={projectStateMessage}
            />
        </div>
    );
}

ProjectInProgressContent.propTypes = propTypes;
ProjectInProgressContent.defaultProps = defaultProps;
