import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "./MultiProjectPopup.css";
import { responsiveClassName } from "../../../../utils/responsive";
import DivInteractive from "../DivInteractive";
import { formatDate } from "../../../../utils/format";
import { useCurrentProject, useUser } from "../../../../Redux/useGlobalState";
import { ActionType } from "../../../../Redux/actions";
import { useLogout } from "../../../../utils/hooks";

const propTypes = {
    popupOpen: PropTypes.bool,
    closePopup: PropTypes.func,
};

const defaultProps = {
    popupOpen: false,
    closePopup: null,
};

/**
 * @param {TUser} user
 * @param {boolean} manyProjects
 * @returns {boolean}
 */
const allProjectsCancelled = (user, manyProjects) => {
    let projectCount = 0,
        cancelledCount = 0;
    user.addresses?.forEach((address) => {
        const { projects } = address;
        projectCount += projects.length;
        cancelledCount += projects.filter(
            (project) => project.isCancelled
        ).length;
    });
    return (
        projectCount === cancelledCount &&
        (!manyProjects || (manyProjects && projectCount > 1))
    );
};

/**
 * @param {{popupOpen: boolean, closePopup: Function, dailyWorkGroup: TDailyWorkGroup}} props
 */
export default function MultiProjectPopup({ popupOpen, closePopup }) {
    const user = useUser();
    const currentProject = useCurrentProject();
    const dispatch = useDispatch();

    const currentProjects = { GroupName: "Current Projects", Projects: [] };
    const completedProjects = { GroupName: "Completed Projects", Projects: [] };
    const canceledProjects = {
        GroupName: "Canceled Projects",
        Projects: [],
    };

    const projectGroups = [
        currentProjects,
        completedProjects,
        canceledProjects,
    ];
    user.addresses?.forEach((address) => {
        address.projects.forEach((project) => {
            const structProject = {
                Address: address.fullAddress,
                ProjectId: project.id,
                ProjectType: project.projectType || "(project type not found)",
                ContractSignedDate: new Date(project.contractSignatureDate),
                BrandName: address.brand.name,
                IsCancelled: project.isCancelled,
                isSelected: project.id === currentProject.projectId,
                isCancelled: project.isCancelled,
            };
            if (!project.isCancelled) {
                if (project.currentProjectStatus.alias !== "ProjectComplete")
                    currentProjects.Projects.push(structProject);
                else completedProjects.Projects.push(structProject);
            } else canceledProjects.Projects.push(structProject);
        });
    });

    const { logout } = useLogout();
    const allCancelled = allProjectsCancelled(user);
    const closeAction = (!allCancelled && closePopup) || logout;

    return (
        <Modal
            show={popupOpen}
            onHide={closePopup}
            dialogClassName={responsiveClassName("cp-modal-dialog")}
            contentClassName="cp-modal-content"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body className={responsiveClassName("modal-body")}>
                <div className="modal-title" id="divDailyWorkTitle">
                    <div className="gotham-bold-cloud-burst-24px modal-title-text">
                        {(!allCancelled && "Select a Project to View") ||
                            "Your Projects"}
                    </div>
                    <DivInteractive
                        className="fontawesome6pro-solid-normal-abbey-24px modal-title-close"
                        onClick={closeAction}
                        onKeyUp={() => false}
                    >
                        
                    </DivInteractive>
                </div>
                <div className="multi-project-popup-content">
                    {projectGroups.map(
                        (projectGroup, index) =>
                            !!projectGroup.Projects.length && (
                                <div
                                    className="multi-project-popup-project-group"
                                    key={`projectGroup_${index.toString()}`}
                                >
                                    <div className="gotham-bold-cloud-burst-20px multi-project-popup-project-group-name">
                                        {projectGroup.GroupName}
                                    </div>
                                    <div className="multi-project-popup-projects">
                                        {projectGroup.Projects.map(
                                            (project, index) => (
                                                <DivInteractive
                                                    className={`multi-project-popup-project ${
                                                        project.isSelected &&
                                                        "multi-project-popup-project-selected"
                                                    } ${
                                                        project.IsCancelled &&
                                                        "multi-project-popup-project-cancelled"
                                                    }`}
                                                    key={`project_${index.toString()}`}
                                                    onClick={() => {
                                                        if (project.IsCancelled)
                                                            return false;
                                                        dispatch({
                                                            type: ActionType.SET_SELECTED_PROJECT_ID,
                                                            selectedProjectId:
                                                                project.ProjectId,
                                                        });
                                                        closePopup();
                                                    }}
                                                >
                                                    <div
                                                        className={`gotham-medium-${
                                                            project.isSelected &&
                                                            !project.IsCancelled
                                                                ? "allports"
                                                                : "black"
                                                        }-20px multi-project-popup-address ${
                                                            project.isSelected &&
                                                            !project.IsCancelled &&
                                                            "multi-project-popup-address-selected"
                                                        }`}
                                                    >
                                                        {project.Address}
                                                    </div>
                                                    {/* ** commented out to add later ** */}
                                                    {/* <div className="gotham-book-normal-black-16px multi-project-popup-project-type">
                                                        {project.ProjectType}
                                                    </div> */}
                                                    <div className="gotham-italic-abbey-14px multi-project-popup-contract-signed">
                                                        Contract Signed on{" "}
                                                        {formatDate(
                                                            project.ContractSignedDate,
                                                            "",
                                                            { yearDigits: 2 }
                                                        )}{" "}
                                                        with {project.BrandName}
                                                    </div>
                                                </DivInteractive>
                                            )
                                        )}
                                    </div>
                                </div>
                            )
                    )}
                </div>
                <div className="daily-work-modal-footer">
                    <Button
                        onClick={closeAction}
                        className="gotham-book-normal-cloud-burst-18px daily-work-modal-button"
                    >
                        {(!allCancelled && "Close") || "Log Out"}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

MultiProjectPopup.propTypes = propTypes;
MultiProjectPopup.defaultProps = defaultProps;

export { allProjectsCancelled };
