import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    useCurrentAddress,
    useCurrentProject,
    useNotifications,
    useUserSession,
} from "../Redux/useGlobalState";
import useAPIService from "../APIService/apiService";
import { ActionType } from "../Redux/actions";
import { ENotificationType } from "../Main/MainContainer/Content/Project/ProjectState/ProjectStateMessage/Notification/eNotificationType";

const useQueryString = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    function QueryString() {
        const params = {};
        searchParams.forEach((value, param) => {
            params[param.toLowerCase()] = value;
        });

        this.get = (paramName) => {
            return params[paramName.toLowerCase()];
        };
    }

    return new QueryString();
};

const useActions = () => {
    const dispatch = useDispatch();
    const { apiPut } = useAPIService();
    const currentProject = useCurrentProject();
    const notifications = useNotifications();
    const { isAdminUser } = useUserSession();

    const dismissNotification = async (notification) => {
        if (!notification) return;
        if (isAdminUser) return;

        await apiPut(
            `Projects/${currentProject.projectId}/Notifications`,
            {
                notificationId: notification.id,
            },
            null,
            { noResponseData: true }
        );

        dispatch({
            type: ActionType.SET_NOTIFICATIONS,
            notifications: notifications.filter(
                (notification) =>
                    notification.notificationTypeAlias !==
                    ENotificationType.ProjectStartDateChanged
            ),
        });
    };

    const dismissNotifications = async (notificationsFilter) => {
        const notification = notifications.find(notificationsFilter);

        await dismissNotification(notification);
    };

    return {
        dismissNotification,
        dismissNotifications,
    };
};

const useLogout = () => {
    const { logout: auth0logout } = useAuth0();
    const currentAddress = useCurrentAddress();
    const { isAdminUser } = useUserSession();
    const { dismissNotifications } = useActions();

    const logoutUrl = () => {
        if (isAdminUser) return `${window.location.origin}/admin`;

        const brandWebsiteUrl = currentAddress.brand?.brandWebsiteUrl;
        if (!brandWebsiteUrl) return null;

        const redirectUrl = encodeURIComponent(`${brandWebsiteUrl}/log-out`);
        return `${window.location.origin}/logout?redirectUrl=${redirectUrl}`;
    };

    return {
        logout: async (normal) => {
            if (normal)
                await dismissNotifications(
                    (notification) =>
                        notification.notificationTypeAlias ===
                        ENotificationType.ProjectStartDateChanged
                );

            auth0logout({
                logoutParams: {
                    returnTo:
                        logoutUrl() ||
                        process.env.REACT_APP_LOGOUT_URL ||
                        `${window.location.origin}/home`,
                },
            });
        },
    };
};

export { useQueryString, useLogout, useActions };
