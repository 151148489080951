import React from "react";
import PropTypes from "prop-types";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import Foreman from "../Foreman/Foreman";
import { EProjectStatus } from "../../../../../../globals/globals";

const propTypes = {
    projectStatus: PropTypes.instanceOf(Object),
    projectStateStamp: PropTypes.instanceOf(Object),
    projectStateMessage: PropTypes.instanceOf(Object),
};

const defaultProps = {
    projectStatus: EProjectStatus.ProjectInProgress,
    projectStateStamp: null,
    projectStateMessage: null,
};

export default function ProjectInProgressContainer({
    projectStatus,
    projectStateStamp,
    projectStateMessage,
}) {
    const projectStatusAlias = projectStatus?.alias;

    const fixedColumnWidthPx =
        (projectStatusAlias === "ProjectInProgress" && 160) ||
        (projectStatusAlias === "ProjectComplete" && 250) ||
        (projectStatusAlias === "HomeProtection" && 250) ||
        270;

    const fixLeftColumn = projectStatusAlias === "ProjectInProgress";

    return (
        <div className="container project-state-content-container">
            <div
                className="row"
                style={{
                    left: 0,
                }}
            >
                <div
                    id="divContentLeft"
                    className="col-sm-6"
                    style={{
                        minWidth: !fixLeftColumn ? "326px" : undefined,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width:
                            (isDesktop &&
                                (fixLeftColumn
                                    ? `${fixedColumnWidthPx}px`
                                    : `calc(100% - ${
                                          fixedColumnWidthPx + 10
                                      }px`)) ||
                            "100%",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            marginBottom: isMobile ? "auto" : "10px",
                        }}
                    >
                        {isDesktop && projectStateStamp}
                        {isDesktop &&
                            projectStatusAlias === "ProjectInProgress" && (
                                <Foreman label />
                            )}
                    </div>
                </div>
                {isBrowser && (
                    <div
                        id="divContentRight"
                        className="col-sm-6"
                        style={{
                            minWidth: fixLeftColumn ? "350px" : undefined,
                            width: fixLeftColumn
                                ? `calc(100% - ${fixedColumnWidthPx}px`
                                : `${fixedColumnWidthPx}px`,
                        }}
                    >
                        {projectStateMessage}
                    </div>
                )}
                {isMobile && projectStateMessage}
            </div>
        </div>
    );
}

ProjectInProgressContainer.propTypes = propTypes;
ProjectInProgressContainer.defaultProps = defaultProps;
