import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { decodeToken } from "react-jwt";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorBoundary } from "react-error-boundary";
import useAPIService from "../APIService/apiService";
import { ActionType } from "../Redux/actions";
import { useEffectAsync } from "../Hooks/hooks";
import {
    TProject,
    TUser,
    useUser,
    useUserSession,
} from "../Redux/useGlobalState";
import appConfig from "../appConfig.json";
import MainContainer from "./MainContainer/MainContainer";
import UserIsActive from "./UserIsActive";
import "../brands/styles/brand_0.css";
import useErrorHandler from "../ErrorHandling/useErrorHandler";
import { useQueryString } from "../utils/hooks";
import { EErrorType } from "../ErrorHandling/EErrorType";
import useEventHandler from "../EventHandling/useEventHandler";
import { EEventType } from "../EventHandling/EEventType";
import ErrorPage from "../ErrorHandling/ErrorPage";
import { ENotificationType } from "./MainContainer/Content/Project/ProjectState/ProjectStateMessage/Notification/eNotificationType";
import { sameDate, today } from "../utils/dateTime";

const test = appConfig.TEST;

/**
 * @param {TUser} user
 * @param {number} selectedProjectId
 * @returns
 */
const calcCurrentProject = (user, selectedProjectId) => {
    /**
     * @type {TProject}
     */
    let currentProject = null;
    let currentAddress = null;
    let createdDate = null;

    user.addresses?.forEach((address) => {
        address?.projects.forEach((project) => {
            if (selectedProjectId) {
                if (project?.id === selectedProjectId) {
                    currentProject = project;
                    currentAddress = address;
                }
            } else if (
                !createdDate ||
                (currentProject.isCancelled &&
                    (!project.isCancelled ||
                        currentProject.projectCancelledDate <
                            project.projectCancelledDate)) ||
                (!currentProject.isCancelled &&
                    createdDate < project.createdDate &&
                    (currentProject.isCancelled || !project.isCancelled))
            ) {
                currentProject = project;
                currentAddress = address;
                createdDate = currentProject.createdDate;
            }
        });
    });

    return { projectId: currentProject?.id, addressId: currentAddress?.id };
};

const getAddress = async (apiGet, addressId) => {
    let address = null;
    if (addressId) {
        const addressUri = `Addresses/${addressId}`;
        address =
            (await apiGet(addressUri, null, {
                allowStatusCodes: [404],
            })) || null;
    }
    return address;
};

const getProject = async (apiGet, projectId) => {
    let project = null;
    if (projectId) {
        const projectUri = `Projects/${projectId}`;
        project =
            (await apiGet(projectUri, null, {
                allowStatusCodes: [404],
            })) || null;
    }
    return project;
};

const getOffers = async (
    isAdminUser,
    personId,
    qsEmailAddress,
    apiGet,
    dispatch,
    handleError
) => {
    if (isAdminUser && !personId && !qsEmailAddress) return {};
    try {
        const fetchUri = "Offers";
        const offers = await apiGet(fetchUri);

        dispatch({
            type: ActionType.SET_OFFERS,
            offers: offers,
        });
    } catch (e) {
        handleError(e, "Error in getOffers", EErrorType.API);
    }
};

const dispathIsAdminLookup = (
    isAdminUser,
    personId,
    qsEmailAddress,
    user,
    dispatch
) => {
    const isAdminLookup = isAdminUser && !!(personId || qsEmailAddress);

    let notFoundMessage = "customer not found - ";
    notFoundMessage += (personId && `personId: ${personId}`) || "";
    notFoundMessage +=
        (qsEmailAddress && ` emailAddress: ${qsEmailAddress}`) || "";

    dispatch({
        type: ActionType.SET_CUSTOMER_NOT_FOUND,
        customerNotFound:
            isAdminLookup && !user.userId ? notFoundMessage : false,
    });
};

const msgErrorTestEmail = (emailAddress) => {
    return `${emailAddress} is a special user for error testing.`;
};

const isErrorTestEmail = (userEmailAddress, testEmailAddress) => {
    if (userEmailAddress === testEmailAddress)
        throw new Error(msgErrorTestEmail(userEmailAddress));
};

const checkTestSlowLoading = (user, numPings) => {
    const { emailAddress } = user;
    if (
        (emailAddress === test.SLOW_LOADING_EMAIL && numPings < 5) ||
        (emailAddress === test.NO_LOADING_EMAIL &&
            numPings < appConfig.LOGIN_PINGS_MAX + 1)
    )
        user.isActive = false;
};

const dispatchUser = (
    dispatch,
    user,
    currentProject,
    currentAddress,
    contact,
    notifications
) => {
    const userIsActive = user.isActive;
    dispatch({
        type: ActionType.SET_PERSON,
        user: userIsActive ? user : { userId: 0, isActive: false },
    });
    dispatch({
        type: ActionType.SET_CURRENT_PROJECT,
        currentProject: userIsActive ? currentProject : { projectId: 0 },
    });
    dispatch({
        type: ActionType.SET_SELECTED_STATUS,
        selectedProjectStatus: currentProject?.currentProjectStatus.alias,
    });
    dispatch({
        type: ActionType.SET_CURRENT_ADDRESS,
        currentAddress: userIsActive ? currentAddress : null,
    });
    dispatch({
        type: ActionType.SET_CONTACT,
        contact: userIsActive ? contact : null,
    });
    dispatch({
        type: ActionType.SET_NOTIFICATIONS,
        notifications: userIsActive ? notifications : null,
    });
};

const getUser = async (isAdminUser, qsEmailAddress, personId, apiCall) => {
    const fetchUri = !(isAdminUser && qsEmailAddress)
        ? `Users/${personId}`
        : `Users/${qsEmailAddress}`;

    const user =
        (isAdminUser &&
            !personId &&
            !qsEmailAddress && { userId: 0, isActive: true }) ||
        (await apiCall.apiGet(fetchUri, null, {
            allowStatusCodes: [404],
            isAdminLookup: isAdminUser,
        })) ||
        {};
    if (user.emailAddress === test.NO_LOADING_EMAIL) user.isActive = false;

    if (!isAdminUser)
        await apiCall.apiPut(
            `Users/${personId}/login`,
            {
                userId: user.userId,
            },
            null,
            { noResponseData: true }
        );

    return user;
};

/**
 * @typedef {Object} PersonParams
 * @property {number} personId - The unique identifier for the person.
 * @property {boolean} isAdminUser - Indicates if the user is an admin.
 * @property {string} qsEmailAddress - The email address of the person.
 * @property {number} selectedProjectId - The selection from the multi-project modal.
 * @property {number} maxPings - Maximum number of pings allowed.
 * @property {Function} setUserIsActive
 * @property {Function} setProjectNotAvailable
 */

/**
 * @typedef {Object} Handlers
 * @property {Function} handleError
 * @property {Function} handleEvent
 */

const getPerson = async (
    setProgress,
    /**
     * @type {PersonParams}
     */
    personParams,
    getAccessTokenSilently,
    apiCall,
    dispatch,
    /**
     * @type {Handlers}
     */
    handlers,
    numPings = 0
) => {
    let { personId } = personParams;
    const {
        currentUser,
        isAdminUser,
        qsEmailAddress,
        selectedProjectId,
        maxPings,
        setUserIsActive,
        setProjectNotAvailable,
    } = personParams;

    try {
        setProgress(numPings);

        if (!personId) {
            const token = await getAccessTokenSilently();
            const decodedToken = decodeToken(token);
            personId =
                decodedToken[
                    `${process.env.REACT_APP_AUTH0_CLAIMS_PREFIX}personId`
                ];
        }

        /**
         * @type {TUser}
         */
        let user =
            currentUser ||
            (await getUser(isAdminUser, qsEmailAddress, personId, apiCall));

        isErrorTestEmail(user.emailAddress, test.ERROR_EMAIL1);

        dispathIsAdminLookup(
            isAdminUser,
            personId,
            qsEmailAddress,
            user,
            dispatch
        );

        const { projectId, addressId } = calcCurrentProject(
            user,
            selectedProjectId
        );
        /**
         * @type {TProject}
         */
        let currentProject = await getProject(apiCall.apiGet, projectId);
        let currentAddress = await getAddress(apiCall.apiGet, addressId);
        let contact = null,
            notifications = null;
        if (projectId) {
            const contactUri = `Projects/${projectId}/Contact`;
            contact =
                (await apiCall.apiGet(contactUri, null, {
                    allowStatusCodes: [404],
                })) || null;

            const notificationsUri = `Projects/${projectId}/Notifications`;
            notifications =
                (await apiCall.apiGet(notificationsUri, null, {
                    allowStatusCodes: [404],
                })) || null;

            const onTheWayUri = `Trips?projectId=${projectId}`;
            let onTheWayTrips =
                (await apiCall.apiGet(onTheWayUri, null, {
                    allowStatusCodes: [404],
                })) || [];
            onTheWayTrips = onTheWayTrips.filter(
                (trip) =>
                    !trip.dateTripEnd && sameDate(trip.dateTripStart, today())
            );
            if (onTheWayTrips.length)
                notifications.push({
                    notificationType: {
                        alias: ENotificationType.ForemanOnTheWay,
                    },
                    trackerLink: onTheWayTrips[0].trackerLink,
                });
        }

        /* for testing only - will be removed
            if (testSetActive || testSetPings < numPings) user.isActive = true;
            else user.isActive = false;
        */

        /* for testing missing data */
        if (user.emailAddress === test.USER_NO_DATA) {
            currentProject = { projectId: 0 };
            currentAddress = null;
            user = { userId: 0, isActive: true };
            user.addresses = null;
            contact = null;
        }

        dispatchUser(
            dispatch,
            user,
            currentProject,
            currentAddress,
            contact,
            notifications
        );

        checkTestSlowLoading(user, numPings);

        handlers.handleEvent(EEventType.Login, user);

        const { emailAddress } = user;
        if (emailAddress === test.SLOW_LOADING_EMAIL && numPings < 5)
            user.isActive = false;
        if (numPings < maxPings && !user.isActive)
            setTimeout(
                () =>
                    void getPerson(
                        setProgress,
                        {
                            personId,
                            isAdminUser,
                            qsEmailAddress,
                            maxPings,
                            setUserIsActive,
                            setProjectNotAvailable,
                        },
                        getAccessTokenSilently,
                        apiCall,
                        dispatch,
                        handlers,
                        numPings + 1
                    ),
                appConfig.LOGIN_PINGS_INTERVAL_SECONDS * 1000
            );
        else if (user.isActive) setUserIsActive(true);
        else setProjectNotAvailable(true);
    } catch (e) {
        handlers.handleError(e, "Error in getPerson", EErrorType.API);
    }
};

export default function Main() {
    const maxPings = appConfig.LOGIN_PINGS_MAX;

    const [progress, setProgress] = useState();
    const [userIsActive, setUserIsActive] = useState(false);
    const [projectNotAvailable, setProjectNotAvailable] = useState(false);

    /* for testing only - will be removed
        const [testSetActive] = useState(Math.random() < 0.5);
        const [testSetPings] = useState(
            Math.random() < 0.5 ? Math.floor(maxPings * Math.random()) : maxPings
        );
    */

    const { apiGet, apiPut } = useAPIService();
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const { handleError } = useErrorHandler();
    const { handleEvent } = useEventHandler();
    const { isAdminUser, selectedProjectId } = useUserSession();

    const queryString = useQueryString();
    const qsPersonId = queryString.get("personId");
    const qsEmailAddress = queryString.get("emailAddress");

    let personId = isAdminUser && qsPersonId ? parseInt(qsPersonId) : null;

    /* for testing only */
    if (!personId) personId = test.PERSON_ID;

    const user = useUser();

    useEffectAsync(() => {
        getPerson(
            setProgress,
            {
                personId,
                currentUser: user,
                isAdminUser,
                qsEmailAddress,
                selectedProjectId,
                maxPings,
                setUserIsActive,
                setProjectNotAvailable,
            },
            getAccessTokenSilently,
            { apiGet, apiPut },
            dispatch,
            { handleError, handleEvent }
        );
    }, [selectedProjectId]);

    useEffectAsync(
        () =>
            getOffers(
                isAdminUser,
                personId,
                qsEmailAddress,
                apiGet,
                dispatch,
                handleError
            ),
        []
    );

    /* for testing only */
    try {
        isErrorTestEmail(user.emailAddress, test.ERROR_EMAIL2);
    } catch (e) {
        handleError(e, "Error in Main", EErrorType.Test, { user: user });
    }

    return (
        (!userIsActive &&
            ((projectNotAvailable && <MainContainer noData />) || (
                <UserIsActive
                    user={user}
                    userIsActive={userIsActive}
                    progress={progress}
                    projectNotAvailable={projectNotAvailable}
                />
            ))) || (
            <ErrorBoundary
                FallbackComponent={ErrorPage}
                onError={(error, info) => {
                    handleError(
                        error,
                        "Error in Main Container",
                        EErrorType.Boundary,
                        {
                            user: user,
                            componentStack: info.componentStack,
                        }
                    );
                }}
            >
                <MainContainer />
            </ErrorBoundary>
        )
    );
}

export { getPerson };
