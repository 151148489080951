import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { BrowserView } from "react-device-detect";
import upImage from "../../../../../static/img/file--1x-png-3@1x.png";
import "./ProjectStateDetails.css";
import { EProjectStatus } from "../../../../../globals/globals";
import DivInteractive from "../../DivInteractive";

const propTypes = {
    isOpen: PropTypes.bool,
    isHomeProtection: PropTypes.bool,
    project: PropTypes.instanceOf(Object),
    stateIndex: PropTypes.number,
};

const defaultProps = {
    isOpen: false,
    isHomeProtection: false,
    project: null,
    stateIndex: null,
};

export default function ProjectStateDetails(props) {
    const { isOpen, isHomeProtection, project, stateIndex } = props;

    const displayResponsive = () => {
        const divProjectStateDetailsText = document.getElementById(
            `divProjectStateDetailsText_${stateIndex}`
        );
        if (!divProjectStateDetailsText) return;

        divProjectStateDetailsText.style.display =
            document.getElementById(`divProjectStateHeader_${stateIndex}`)
                .offsetWidth < 370
                ? "none"
                : "inline";
    };

    useEffect(() => displayResponsive(), []);

    window.addEventListener("resize", () => displayResponsive(), true);

    return (
        <DivInteractive
            className="project-state-details"
            style={{
                display:
                    isHomeProtection &&
                    project.currentProjectStatus?.alias ===
                        EProjectStatus.ProjectComplete
                        ? "none"
                        : "flex",
            }}
        >
            <BrowserView>
                <div
                    id={`divProjectStateDetailsText_${stateIndex}`}
                    className={`see-details ${
                        isOpen
                            ? "gotham-book-normal-cloud-burst-14px"
                            : "gotham-book-normal-allports-14px"
                    }`}
                >
                    {isOpen && "Hide Details"}
                    {!isOpen && "See Details"}
                </div>
            </BrowserView>
            <div
                className="text-2-1 fontawesome6prosolid-solid-normal-allports-22px"
                style={{ marginBottom: !isOpen ? "1px" : "-3px" }}
            >
                {isOpen && <img className="image" src={upImage} alt="" />}
                {!isOpen && ""}
            </div>
        </DivInteractive>
    );
}

ProjectStateDetails.propTypes = propTypes;

ProjectStateDetails.defaultProps = defaultProps;
