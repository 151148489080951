import React from "react";
import PropTypes from "prop-types";
import "./Workflow.css";
import { Card } from "react-bootstrap";
import { TWorkflowItem } from "../../../../../../../Redux/useGlobalState";
import ProjectStateCompleted from "../../ProjectStateCompleted/ProjectStateCompleted";

const propTypes = {
    workflow: PropTypes.arrayOf(Object),
};

const defaultProps = {
    workflow: [],
};

/**
 * @param {TWorkflowItem} workflowItem
 * @returns
 */
const sortPosition = (workflowItem) => {
    switch (workflowItem.title) {
        case "Permit Process Initiated":
            return 0;
        case "Engineering Evaluation":
            return 1;
        case "Permit Process Completed":
            return 2;
        case "Utilities Marking":
            return 3;
        case "Project Plan Finalization":
            return Infinity;
        default:
            return 4;
    }
};

const sortWorkflow = (workflowItem1, workflowItem2) => {
    return sortPosition(workflowItem1) > sortPosition(workflowItem2) ? +1 : -1;
};

/**
 * @typedef {Object} Props
 * @property {TWorkflowItem[]} workflow
 */

/**
 * @param {Props} props
 */
export default function Workflow(props) {
    const { workflow } = props;

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(305px, 1fr))",
                gap: "15px",
            }}
        >
            {[...workflow].sort(sortWorkflow).map((workflowItem, index) => {
                const { isCompleted } = workflowItem;

                return (
                    <Card
                        className="workflow-card"
                        key={`workflowItem_${index.toString()}`}
                    >
                        <Card.Body>
                            <Card.Title
                                className="gotham-bold-black-18px"
                                style={{ display: "flex" }}
                            >
                                <ProjectStateCompleted
                                    isComplete={isCompleted}
                                    isGrayed={!isCompleted}
                                    className="workflow-checkbox"
                                    isWorkflow
                                />
                                &nbsp;
                                {workflowItem.title}
                            </Card.Title>
                            <Card.Text>{workflowItem.description}</Card.Text>
                        </Card.Body>
                    </Card>
                );
            })}
        </div>
    );
}

Workflow.propTypes = propTypes;
Workflow.defaultProps = defaultProps;
