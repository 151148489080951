import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
    const { logout } = useAuth0();

    const logOut = () => {
        logout({
            logoutParams: {
                returnTo:
                    process.env.REACT_APP_LOGOUT_URL ||
                    `${window.location.origin}/home`,
            },
        });
    };

    return <button onClick={() => logOut()}>Log Out</button>;
};

export default LogoutButton;
